import * as React from 'react';
import { observer, inject } from 'mobx-react';
import CreateProductStore from './CreateProductStore';
import { RouteComponentProps } from 'react-router-dom';

interface Props extends RouteComponentProps {
    createProductStore?: CreateProductStore;
}

@inject('createProductStore')
@observer
export class DIFOTTrackerLayout extends React.Component<Props, {}> {
    public render(): React.ReactNode {
        return <>Coming soon...</>;
    }
}
export default DIFOTTrackerLayout;
