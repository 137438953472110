import React from 'react';
import { makeStyles } from '@material-ui/core';

const useLoadingStyles = makeStyles({
    Skeleton: {
        animation: '$Skeleton-keyframes-pulse 1.5s ease-in-out .5s infinite',
        marginBottom: 4,
        marginTop: 4,
        display: 'grid',
        height: 35,
        backgroundColor: '#0000000d',
    },
    '@keyframes Skeleton-keyframes-pulse': {
        '0%': { opacity: 1 },
        '50%': { opacity: 0.4 },
        '100%': { opacity: 1 },
    },
});

export const Loading = () => {
    const loadingStyles = useLoadingStyles();
    return (
        <>
            {[...Array(12)].map((_, i) => (
                <div className={`${loadingStyles.Skeleton}`} key={i} />
            ))}
        </>
    );
};
