import { HttpClient } from '../http-helpers';

export interface ProductListItem {
    productCode: string;
}

export interface ProductList {
    items: ProductListItem[];
}

export const fetchProductList = async (httpClient: HttpClient, baseUrl: string) => {
    const response = await httpClient.get<ProductList>(`${baseUrl}/api/v10/products`);
    return response.parsedBody!;
};
