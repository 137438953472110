import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { ActualCostLogEntry } from '../../utils/api/actual-cost-log-search';
import { Card, CardBody } from '@vulcan/vulcan-materialui-theme';
import ReactTable, { FinalState } from 'react-table';
import { PaginationComponent } from './components/pagination-component';
import { useInventoryApi } from 'src/ActualCostAdjustmentLog/utils/api/use-inventory-api';
import { makeStyles } from '@material-ui/core/styles';
import { Loading } from './components/loading';
import { ActualCostLogRows } from './components/actual-cost-log-rows';
import { NoEntriesFound } from './components/no-entries-found';
import { useNotifier } from 'src/Shared/Notification/notification-context';
import { TableHeader } from './components/table-header';
import { useDisplayMode, DisplayMode } from './hooks/use-display-mode';

export interface ActualCostLogProp {
    productCodes: string[];
    warehouseCodes: string[];
}

const useStyles = makeStyles({
    table: {
        display: 'grid',
    },
    tableBody: {
        display: 'grid',
    },
    tableBodyFullView: {
        minHeight: 550,
    },
    tableBodyWithData: {
        gridAutoRows: 'min-content',
    },
    emptyTableBody: {
        justifyItems: 'center',
        alignItems: 'center',
    },
});

interface DataSource {
    skip: number;
    take: number;
    data: ActualCostLogEntry[];
    noMoreSearchResults: boolean;
}

const defaultState = { skip: 0, take: 15, data: [], noMoreSearchResults: false };

export const ActualCostLog = (prop: ActualCostLogProp) => {
    const styles = useStyles();
    const [loading, setLoading] = useState(false);
    const { searchActualCostLog } = useInventoryApi();
    const [dataSource, setDataSource] = useState<DataSource>(defaultState);
    const { raiseError, raiseWarning } = useNotifier();
    const { displayMode } = useDisplayMode();
    const loadData = useCallback(
        async (skip: number, take: number) => {
            setLoading(true);
            try {
                const response = await searchActualCostLog({ ...prop, skip, take });
                const data = response?.items ?? [];
                if (skip > 0 && data.length === 0) {
                    raiseWarning('No more search results');
                    setLoading(false);
                    return;
                }
                setDataSource({ skip, take, data, noMoreSearchResults: false });
            } catch {
                raiseError('There was an error trying to retrieve actual cost adjustments. Please refresh your browser and try again.');
            }
            setLoading(false);
        },
        [searchActualCostLog, setLoading, setDataSource, prop, raiseError, raiseWarning]
    );
    const paginationCallback = useCallback(
        async (fetchNext: boolean) => {
            const { skip, take } = dataSource;
            const newSkip = fetchNext ? skip + take : Math.max(skip - take, 0);
            await loadData(newSkip, take);
        },
        [dataSource, loadData]
    );

    const paginationComponent = useMemo(() => {
        const props = {
            ...dataSource,
            showNext: () => paginationCallback(true),
            showPrevious: () => paginationCallback(false),
        };
        return <PaginationComponent {...props} />;
    }, [dataSource, paginationCallback]);

    useEffect(() => {
        if (!prop.productCodes?.length || !prop.warehouseCodes?.length) {
            setDataSource(defaultState);
            return;
        }
        loadData(0, 15);
    }, [prop, loadData]);

    const baseTableBodyStyle = dataSource.data?.length > 0 ? `${styles.tableBody} ${styles.tableBodyWithData}` : `${styles.tableBody} ${styles.emptyTableBody}`;

    const tableBodyStyle = displayMode === DisplayMode.FullView ? `${styles.tableBodyFullView} ${baseTableBodyStyle}` : baseTableBodyStyle;

    return (
        <Card style={{ marginBottom: 0 }}>
            <CardBody>
                <h3>Actual Cost Log</h3>
                <ReactTable data={dataSource.data} columns={columns} loading={loading}>
                    {(state: FinalState<ActualCostLogEntry>) => {
                        const { data, loading } = state;
                        const headers = columns.map((c) => c.Header);
                        return (
                            <div className={styles.table}>
                                <TableHeader headers={headers} />
                                <div className={tableBodyStyle}>{loading ? <Loading /> : data?.length > 0 ? <ActualCostLogRows items={data} /> : <NoEntriesFound />}</div>
                                {paginationComponent}
                            </div>
                        );
                    }}
                </ReactTable>
            </CardBody>
        </Card>
    );
};

const columns = [
    { Header: 'Date', accessor: 'dateTimeInUtc' },
    { Header: 'Warehouse', accessor: 'warehouseCode' },
    { Header: 'Reference', accessor: 'reference' },
    { Header: 'Original Stock', accessor: 'originalStock' },
    { Header: 'Stock Adjustment', accessor: 'stockAdjustment' },
    { Header: 'Original Value', accessor: 'originalValue' },
    { Header: 'Value Adjustment', accessor: 'valueAdjustment' },
    { Header: 'Total Stock', accessor: 'totalStock' },
    { Header: 'Total Value', accessor: 'totalAdjustment' },
    { Header: 'Each Cost', accessor: 'eachCost' },
    { Header: 'Ton Cost', accessor: 'tonCost' },
];
