import React, { useState, useContext, createContext, ReactNode } from 'react';

export const createAppContext = <T,>() => {
    const AppStateContext = createContext<T | undefined>(undefined);
    const SetAppStateContext = createContext<((state: T) => void) | undefined>(undefined);

    function AppContextProvider({ children, contextState }: { children: ReactNode; contextState: T }) {
        const [, setState] = useState<T>(contextState);
        return (
            <AppStateContext.Provider value={contextState}>
                <SetAppStateContext.Provider value={setState}>{children}</SetAppStateContext.Provider>
            </AppStateContext.Provider>
        );
    }

    function useAppState() {
        const context = useContext(AppStateContext);
        if (context === undefined) {
            throw new Error('app state hook can only be used in a component or hook that has been wrapped with a AppContextProvider.');
        }
        return context;
    }

    function useSetAppState() {
        const context = useContext(SetAppStateContext);
        if (context === undefined) {
            throw new Error('set app state hook can only be used in a component or hook that has been wrapped with a AppContextProvider.');
        }
        return context;
    }

    return { AppContextProvider, useAppState, useSetAppState };
};
