import * as React from 'react';
import { Icon } from '@vulcan/vulcan-materialui-theme';
import { IconResources } from '../../Shared/Constants';
import SettingStore from './SettingStore';
import { observer, inject } from 'mobx-react';
import { Switch } from '@material-ui/core';
import { version } from '../../../package.json';

interface Props {
    settingStore?: SettingStore;
}

@inject('settingStore')
@observer
export class SettingFooter extends React.Component<Props, {}> {
    public render(): JSX.Element {
        return (
            <div className='setting-footer-container'>
                <div className='title'>Settings </div>
                <div className='setting'>
                    <span>
                        <p>
                            Sidebar Mini Mode
                            <Switch color='primary' checked={this.props.settingStore!.miniActive} onChange={this.props.settingStore!.handelMiniModeToggle} />
                        </p>
                    </span>
                </div>
                <div className='title'>About</div>
                <Icon className='logo' icon={IconResources.WebsiteLogo} />
                <div className='details'>
                    <span>Inventory Website</span>
                    <span>Version: {version}</span>
                    <span>2020-{this.props.settingStore!.CurrentYear} © Vulcan Steel Ltd.</span>
                </div>
            </div>
        );
    }
}

export default SettingFooter;
