export enum StringResources {
    ErrorAcquiringToken = 'Error Acquiring Token',
    ErrorGettingProfilePhoto = 'Error getting profile photo ',
    NoGetTokenMethod = 'getToken method is not attached to the window object',
    UnexpectedError = 'An unexpected error occurred',
    NoAuthToken = 'No Auth token was found',
    TenantUrlNotFound = 'Cannot find tenant url',
    ErrorAcquiringUserTenants = 'Error acquiring user tenants',
    ErrorLoadingPriceMaintenanceTableException = 'Failed to load pricing data. Please contact support.',
    TableNoRowsFound = 'No items found',
    ErrorLoadingFileException = 'Failed to load the file. Please contact support.',
    SuccessSubmittingNewPrices = 'New prices saved successfully.',
    ErrorSubmittingNewPricesException = 'Failed to save new prices. Please retry or contact support.',
    ErrorValidatingPricesException = 'Failed to validate prices. Please retry or contact support.',
    TooltipFixErrorsBeforeSubmitting = 'Fix all errors before submitting',
    ErrorLoadingStockFiltersException = 'Failed to load stock filters. Please contact support.',
}

export default StringResources;
