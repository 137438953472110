import * as React from 'react';
import { FormInput } from '@vulcan/vulcan-materialui-theme';
import { action } from 'mobx';
import ConfigureAvailabilityStore from './ConfigureAvailabilityStore';
import { inject, observer } from 'mobx-react';

interface Props {
    configureAvailabilityStore?: ConfigureAvailabilityStore;
}

@inject('configureAvailabilityStore')
@observer
export class ConfigureAvailabilityTableSearch extends React.Component<Props, {}> {
    @action private handleSearchInputClear = (): void => {
        this.props.configureAvailabilityStore!.clearSearchResults();
    };

    @action private handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const searchTerm = e.target.value.trim();
        this.props.configureAvailabilityStore!.searchTable(searchTerm);
    };

    public render(): React.ReactNode {
        return (
            <>
                <h3 className='subtitle-title'>Product to configure:</h3>
                <FormInput
                    placeholder='Search Product'
                    showAdornment={true}
                    validated={false}
                    showLabel={false}
                    value={this.props.configureAvailabilityStore!.searchInputValue}
                    showClearIcon={this.props.configureAvailabilityStore!.searchInputValue.length > 0}
                    handleInputChange={this.handleSearchInputChange}
                    handleClearIconClick={this.handleSearchInputClear}
                />
            </>
        );
    }
}
export default ConfigureAvailabilityTableSearch;
