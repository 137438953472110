import { createAppContext } from './create-app-context';

export interface AppConfig {
    apiUrls: {
        inventoryApi: string;
    };
    displayDateFormat: string;
}

export const { AppContextProvider: AppConfigProvider, useAppState: useAppConfig } = createAppContext<AppConfig>();
