import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ProductListDropDown } from './components/product-list-drop-down';
import { WarehouseListDropDown } from './components/warehouse-list-drop-down';
import { ActualCostLog } from './components/actual-cost-log/actual-cost-log';

const useStyles = makeStyles({
    root: {
        display: 'grid',
        alignItems: 'center',
        padding: 20,
    },
    filters: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, minmax(auto,500px))',
    },
    filterItem: { marginRight: 20, marginTop: 10 },
});

export const ActualCostAdjustmentLog = () => {
    const classes = useStyles();
    const [selectedWarehouses, setSelectedWarehouses] = useState<string[]>([]);
    const [selectedProducts, setSelectedProducts] = useState<string[]>([]);
    return (
        <div className={classes.root}>
            <div className={classes.filters}>
                <WarehouseListDropDown className={classes.filterItem} onWarehouseSelectionChanged={setSelectedWarehouses} />
                <ProductListDropDown className={classes.filterItem} onProductSelectionChanged={setSelectedProducts} />
            </div>
            <ActualCostLog productCodes={selectedProducts} warehouseCodes={selectedWarehouses} />
        </div>
    );
};
