import * as React from 'react';
import { observer, inject } from 'mobx-react';
import { Card, CardBody, Icon } from '@vulcan/vulcan-materialui-theme';
import { List, ListItem, ListItemText, Typography, withWidth } from '@material-ui/core';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { WithWidth } from '@material-ui/core/withWidth';
import classNames from 'classnames';
import SecurityStore from '../Security/SecurityStore';
import { IconResources } from '../../Shared/Constants';
import UserSettingModel from '../Security/UserSettingModel';

const TenantsListAppbar = (): JSX.Element => (
    <div className='tenants-app-bar'>
        <div className='tenants-logo-container'>
            <a href='/' className='tenants-logo-img'>
                <Icon size={50} icon={IconResources.WebsiteLogo} />
            </a>
            <a href='/' className='tenants-logo-text'>
                Inventory
            </a>
        </div>
    </div>
);

const TenantsListTitle = (): JSX.Element => (
    <div className='tenants-list-title'>
        <Typography gutterBottom={true} variant='h6' component='h3'>
            Welcome to Inventory
        </Typography>
        <Typography gutterBottom={false} variant='subtitle1' component='h4'>
            Please select a tenant
        </Typography>
    </div>
);

interface Props extends WithWidth, RouteComponentProps {
    securityStore?: SecurityStore;
}

@inject('securityStore')
@observer
export class StyledTenantsList extends React.Component<Props, {}> {
    public render(): JSX.Element {
        const { width } = this.props;

        const tenantsListCardClass = classNames({
            'tenants-list-card': true,
            'tenants-list-card-small': width === 'xs' || width === 'sm',
        });

        return (
            <>
                <TenantsListAppbar />
                <div className='tenants-list-container'>
                    <Card className={tenantsListCardClass}>
                        <TenantsListTitle />
                        <CardBody>
                            <List className='tenants-list' component='nav'>
                                {this.props.securityStore!.userTenants.map((t: UserSettingModel, index: number) => this.renderListItem(t, index))}
                            </List>
                        </CardBody>
                    </Card>
                </div>
            </>
        );
    }

    private renderListItem(tenant: UserSettingModel, key: number): JSX.Element {
        return (
            <ListItem key={key} button={true} data-id={tenant.tenantName} onClick={this.handleTenantClick}>
                <ListItemText primary={tenant.friendlyName} />
            </ListItem>
        );
    }

    private handleTenantClick = (e: React.MouseEvent<HTMLElement>): void => {
        this.props.securityStore!.setSelectedTenant(e.currentTarget.dataset.id!);
        this.props.history.push(this.props.location!.pathname);
    };
}

const WrappedTenantsList = withWidth()(StyledTenantsList);
// tslint:disable-next-line:no-any
const TenantsList = withRouter<any, any>(WrappedTenantsList);
export default TenantsList;
