import React, { useCallback } from 'react';
import { DropDown } from './drop-down';
import { OptionModel } from '@vulcan/vulcan-materialui-theme/build/components';
import { WarehouseListItem } from '../utils/api/fetch-warehouses';
import { useInventoryApi } from '../utils/api/use-inventory-api';
import { useNotifier } from 'src/Shared/Notification/notification-context';

export const WarehouseListDropDown = (props: { className: string; onWarehouseSelectionChanged: (selectedWarehouses: string[]) => void }) => {
    const { onWarehouseSelectionChanged, className } = props;
    const { fetchWarehouseList } = useInventoryApi();
    const { raiseError } = useNotifier();
    const fetchWarehouses = useCallback(async () => {
        try {
            const warehouses = await fetchWarehouseList();
            return warehouses.items;
        } catch {
            raiseError('There was an error trying to retrieve the Warehouse list. Please refresh your browser and try again.');
            return [];
        }
    }, [fetchWarehouseList, raiseError]);
    const mapToOptionModel = useCallback((item: WarehouseListItem) => new OptionModel({ label: item.warehouseCode, value: item.warehouseCode }), []);
    return (
        <DropDown
            onSelectionChanged={onWarehouseSelectionChanged}
            placeholderText='Search for a warehouse'
            searchPromptText='Specify the first few characters of the warehouse'
            noSearchResultFoundText='Cannot find the specified warehouse'
            className={className}
            formLabel='Warehouse'
            loadOptions={fetchWarehouses}
            mapToOptionModel={mapToOptionModel}
            fieldsToSearch={['warehouseCode']}
        />
    );
};
