import { createAppContext } from 'src/ActualCostAdjustmentLog/utils/app-context/create-app-context';
import React, { useCallback, useMemo, useReducer } from 'react';
import { exhaustiveCheck } from 'src/ActualCostAdjustmentLog/utils/exhaustive-check';
import { Snackbar, IconButton, makeStyles } from '@material-ui/core';
import Close from '@material-ui/icons/Close';

export enum NotificationType {
    Error = 'error',
    Warning = 'warning',
}

export interface ErrorNotification {
    type: NotificationType.Error;
    message: string;
}

export interface WarningNotification {
    type: NotificationType.Warning;
    message: string;
}

export type Notification = ErrorNotification | WarningNotification;

export type NotificationEntry = Notification & { id: number };

export interface NotificationContext {
    notifications: NotificationEntry[];
    addNotification: (notificationEntry: NotificationEntry) => void;
    removeNotification: (notificationEntry: NotificationEntry) => void;
}

const { AppContextProvider: NotificationContextProvider, useAppState: useNotificationContext } = createAppContext<NotificationContext>();

const addNotificationAction = (entry: NotificationEntry) => ({ type: 'AddNotification', item: entry } as const);
const removeNotificationAction = (entry: NotificationEntry) => ({ type: 'RemoveNotification', item: entry } as const);
type NotificationActions = ReturnType<typeof addNotificationAction> | ReturnType<typeof removeNotificationAction>;

const notificationReducer = (state: NotificationEntry[], action: NotificationActions) => {
    switch (action.type) {
        case 'AddNotification':
            return [...state, action.item];
        case 'RemoveNotification':
            return state.filter((n) => n.id !== action.item.id);
    }
    exhaustiveCheck(action);
};

const NotificationProvider: React.FC = (props) => {
    const [notifications, dispatch] = useReducer(notificationReducer, []);
    const addNotification = useCallback((item: NotificationEntry) => dispatch({ type: 'AddNotification', item }), [dispatch]);
    const removeNotification = useCallback((item: NotificationEntry) => dispatch({ type: 'RemoveNotification', item }), [dispatch]);
    const notificationContext: NotificationContext = useMemo(() => ({ notifications, addNotification, removeNotification }), [notifications, addNotification, removeNotification]);

    return <NotificationContextProvider contextState={notificationContext} children={props.children} />;
};

const useNotifier = () => {
    const { addNotification } = useNotificationContext();
    const raiseWarning = useCallback((message: string) => addNotification({ type: NotificationType.Warning, message, id: Date.now() }), [addNotification]);
    const raiseError = useCallback((message: string) => addNotification({ type: NotificationType.Error, message, id: Date.now() }), [addNotification]);
    return { raiseError, raiseWarning };
};

const Notifier = () => {
    const { notifications, removeNotification } = useNotificationContext();
    const onRemove = useCallback((i: NotificationEntry) => removeNotification(i), [removeNotification]);
    return (
        <>
            {notifications.map((n) => (
                <NotificationItem key={n.id} item={n} onRemove={onRemove} />
            ))}
        </>
    );
};

interface NotificationItemProps {
    item: NotificationEntry;
    onRemove: (item: NotificationEntry) => void;
}

const useStyles = makeStyles({
    notification: {
        fontSize: 14,
        fontWeight: 300,
        padding: 10,
    },
    error: {
        backgroundColor: '#f55a4e',
        color: '#fff',
    },
    warning: {
        backgroundColor: '#ff9800',
        color: '#fff',
    },
});

const NotificationItem = ({ item, onRemove }: NotificationItemProps) => {
    const styles = useStyles();
    const onClose = useCallback(() => onRemove(item), [item, onRemove]);
    const iconButton = useMemo(
        () => (
            <IconButton aria-label='Close' color='inherit' size='small' onClick={onClose}>
                <Close />
            </IconButton>
        ),
        [onClose]
    );
    switch (item.type) {
        case NotificationType.Error:
            return notifier(item, onClose, iconButton, `${styles.notification} ${styles.error}`);
        case NotificationType.Warning:
            return notifier(item, onClose, iconButton, `${styles.notification} ${styles.warning}`);
    }
    exhaustiveCheck(item);
};

const notifier = (item: NotificationEntry, onClose: () => void, action: JSX.Element, className: string) => (
    <Snackbar
        key={item.id}
        action={action}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        onClose={onClose}
        message={<>{item.message}</>}
        open={true}
        ContentProps={{ classes: { root: className } }}
    />
);

export { NotificationProvider, useNotifier, Notifier };
