export class TableModel {
    public isChecked = false;
    public profitCentre = '';
    public warehouse = '';
    public binNumber = '';
    public stockClass = '';
    public stockHoldingType = '';
    public standardCost = 0;
    public listPrice = 0;
    public saleUnits = '';
    public purchaseUnits = '';

    constructor(fields?: Partial<TableModel>) {
        Object.assign(this, fields);
    }
}

export default TableModel;
