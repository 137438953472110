export class AppSettings {
    public cacheLocation: string;
    public systemDataFormat: string;
    public displayDataFormat: string;
    public graphApiUrl: string;
    public msLoginUrl: string;
    public postLogoutRedirectUri: string;

    public raygunApiKey: string;
    public azureAppId: string;
    public azureTenantId: string;
    public securityServiceUrl: string;
    public websitepathprefix: string;
    public securityApiUrl: string;
    public salesApiUrl: string;
    public inventoryApiUrl: string;
    public applicationInsightsKey: string;

    constructor() {
        this.cacheLocation = '';
        this.systemDataFormat = '';
        this.displayDataFormat = '';
        this.graphApiUrl = '';
        this.msLoginUrl = '';
        this.postLogoutRedirectUri = '';

        this.raygunApiKey = '';
        this.azureAppId = '';
        this.azureTenantId = '';
        this.securityServiceUrl = '';
        this.websitepathprefix = '';
        this.securityApiUrl = '';
        this.salesApiUrl = '';
        this.inventoryApiUrl = '';
        this.applicationInsightsKey = '';
    }
}

export default AppSettings;

declare global {
    interface Window {
        appSettings: AppSettings;
        isAuthenticated: boolean;
        getToken: Function | null;
        // tslint:disable-next-line:no-any
        adalInstance: any;
        // tslint:disable-next-line:no-any
        Raygun: any;
        // tslint:disable-next-line:no-any
        authenticate: any;
    }
}

window.appSettings = window.appSettings || {};
window.adalInstance = null;
window.getToken = null;
window.Raygun = null;
