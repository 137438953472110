import React from 'react';
import { Button } from '@vulcan/vulcan-materialui-theme';
import { ExcelGenerator } from '@vulcan/component-lib/dist/utils';
import { inject, observer } from 'mobx-react';
import ViewProductPriceMaintenanceStore from './ViewProductPriceMaintenanceStore';
import ViewProductPriceMaintenanceTable from './ViewProductPriceMaintenanceTable';
import { ViewProductPriceMaintenanceTableDataRowModel } from './Models/ViewProductPriceMaintenanceTableDataRowModel';

interface ReorderTableProps {
    viewProductPriceMaintenanceStore?: ViewProductPriceMaintenanceStore;
}

@inject('viewProductPriceMaintenanceStore')
@observer
export class ViewProductPriceMaintenanceExport extends React.Component<ReorderTableProps> {
    private handleExport = (): void => {
        const tableData = this.props.viewProductPriceMaintenanceStore!.tableData.slice();
        const generator = new ExcelGenerator<ViewProductPriceMaintenanceTableDataRowModel>(
            'ProductPrices',
            ViewProductPriceMaintenanceTable.exportableTableColumns,
            tableData,
            undefined,
            ['New Std Cost', 'New List Price']
        );
        generator.generate();
    };

    public render(): JSX.Element {
        return (
            <Button
                disabled={!this.props.viewProductPriceMaintenanceStore!.tableData.length}
                className='export-excel-button'
                type='button'
                color='warning'
                onClick={this.handleExport}
            >
                Export
            </Button>
        );
    }
}

export default ViewProductPriceMaintenanceExport;
