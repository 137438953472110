import { StockWarehousesFilterResponse } from '@vulcan/inventory-api-client/esm/models';

export class WarehouseModel implements StockWarehousesFilterResponse {
  branchId: number;
  warehouseId: number ;
  warehouseCode: string;
  warehouseName: string;
  //TODO: branchCode needs to be implemented maybe?
  branchCode: string = "";

    constructor(branchId: number, branchCode: string, warehouseId: number, code: string, name: string) {
        this.branchId = branchId;
        this.branchCode = branchCode;
        this.warehouseId = warehouseId;
        this.warehouseCode = code;
        this.warehouseName = name;
    }

    public static compareFn = (a: WarehouseModel, b: WarehouseModel): number =>
        a.warehouseCode.localeCompare(b.warehouseCode, undefined, { ignorePunctuation: true, sensitivity: 'base' });
}

export default WarehouseModel;
