import { HttpClient } from '../http-helpers';

export interface ActualCostLogSearch {
    skip: number;
    take: number;
    warehouseCodes: string[];
    productCodes: string[];
}
export interface ActualCostLog {
    items: ActualCostLogEntry[];
}

export interface ActualCostLogEntry {
    id: string;
    dateTimeInUtc: Date;
    warehouseCode: string;
    reference: string;
    originalInStock?: number;
    originalEachCost?: number;
    inStockAdjustment?: number;
    eachCost: number;
    tonCost: number;
}

export const searchActualCostLog = async (httpClient: HttpClient, baseUrl: string, searchRequest: ActualCostLogSearch): Promise<ActualCostLog> => {
    const response = await httpClient.post<ActualCostLog>(`${baseUrl}/api/v10/stock/actualcostadjustments/search`, searchRequest);
    return response.parsedBody!;
};
