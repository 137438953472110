import { TokenCredentials } from '@azure/ms-rest-js';
import { Security } from '@vulcan/security-api-client';
import { SecurityOptions } from '@vulcan/security-api-client/lib/models';
import StringResources from '../../Shared/Constants/StringResources';
import Logger from './Logger';
import { observable, action } from 'mobx';
import SettingsService from '../Middleware/SettingsService';
import moment from 'moment';
import AlertType from 'src/Shared/Alert/AlertType';
import { Sales } from '@vulcan/sales-api-client';
import { Inventory } from '@vulcan/inventory-api-client';
import { SalesOptions } from '@vulcan/sales-api-client/lib/models';
import { InventoryOptions } from '@vulcan/inventory-api-client/lib/models';

export class BaseStore implements Logger {
    @observable private _isLoading: boolean;
    @observable public alert: boolean;
    @observable public alertType: AlertType;
    @observable public alertMessage: string;

    constructor() {
        this._isLoading = false;
        this.alert = false;
        this.alertType = AlertType.Success;
        this.alertMessage = '';
    }

    public get isLoading(): boolean {
        return this._isLoading;
    }

    @action protected setIsLoading(status: boolean): void {
        this._isLoading = status;
    }

    @action public showAlert = (type: AlertType, message: string): void => {
        this.alertType = type;
        this.alertMessage = message;
        this.alert = true;
        // hide the alert after 2 seconds
        setTimeout(() => this.hideAlert(), 2000);
    };

    @action public hideAlert(): void {
        this.alert = false;
    }

    public log(message: string, error?: Error, info?: object): void {
        const customData = { message: message || '', info: info || {} };
        if (window.Raygun) {
            window.Raygun.send(error || new Error('No Error Info'), customData);
        }
    }

    protected get BrowserTodayDate(): moment.Moment {
        return moment();
    }

    public getSecurityClient = async (): Promise<Security> => {
        if (!window.getToken) {
            this.log(StringResources.NoGetTokenMethod);
            throw new Error(StringResources.UnexpectedError);
        }

        let cachedToken: string | null = null;
        try {
            cachedToken = await window.getToken();
        } catch (error) {
            this.log(StringResources.NoAuthToken, error);
            throw new Error(StringResources.UnexpectedError);
        }

        const securityUrl = SettingsService.tenantsUrls.get(window.appSettings.securityApiUrl) || window.appSettings.securityServiceUrl;

        if (!securityUrl) {
            this.log(StringResources.TenantUrlNotFound, new Error(StringResources.NoGetTokenMethod));
            throw new Error(StringResources.UnexpectedError);
        }

        const options: SecurityOptions = {
            baseUri: securityUrl,
        };
        const tokenCred = new TokenCredentials(cachedToken!);
        return new Security(tokenCred, options);
    };

    public getSalesClient = async (): Promise<Sales> => {
        if (!window.getToken) {
            this.log(StringResources.NoGetTokenMethod);
            throw new Error(StringResources.UnexpectedError);
        }

        let cachedToken: string | null = null;
        try {
            cachedToken = await window.getToken();
        } catch (error) {
            this.log(StringResources.NoAuthToken, error);
            throw new Error(StringResources.UnexpectedError);
        }

        const salesUrl = SettingsService.tenantsUrls.get(window.appSettings.salesApiUrl);

        if (!salesUrl) {
            this.log(StringResources.TenantUrlNotFound, new Error(StringResources.TenantUrlNotFound));
            throw new Error(StringResources.UnexpectedError);
        }

        const options: SalesOptions = {
            baseUri: salesUrl,
        };
        const tokenCred = new TokenCredentials(cachedToken!);
        return new Sales(tokenCred, options);
    };

    public getInventoryClient = async (): Promise<Inventory> => {
        if (!window.getToken) {
            this.log(StringResources.NoGetTokenMethod);
            throw new Error(StringResources.UnexpectedError);
        }

        let cachedToken: string | null = null;
        try {
            cachedToken = await window.getToken();
        } catch (error) {
            this.log(StringResources.NoAuthToken, error);
            throw new Error(StringResources.UnexpectedError);
        }

        const inventoryUrl = SettingsService.tenantsUrls.get(window.appSettings.inventoryApiUrl);

        if (!inventoryUrl) {
            this.log(StringResources.TenantUrlNotFound, new Error(StringResources.TenantUrlNotFound));
            throw new Error(StringResources.UnexpectedError);
        }

        const options: InventoryOptions = {
            baseUri: inventoryUrl,
        };
        const tokenCred = new TokenCredentials(cachedToken!);
        return new Inventory(tokenCred, options);
    };
}

export default BaseStore;
