import React from 'react';
import { observable, action } from 'mobx';
import { observer, inject } from 'mobx-react';
import { Sidebar, Header, appStyle, Icon } from '@vulcan/vulcan-materialui-theme';
import { withStyles, WithStyles } from '@material-ui/core';
import classNames from 'classnames';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import TenantsSelect from '../MultiTenant/TenantsSelect';
import SettingFooter from './SettingFooter';
import { Permissions } from '../Security/Permissions';
import SettingStore from './SettingStore';
import { AuthStore, SecurityStore, UserProfileStore } from '../Security';
import { SwitchRoutes, MainRoutes, RouteModel } from '../Routes';
import { IconResources } from 'src/Shared/Constants';

const image = require('src/Shared/Assets/sidebar-background.jpg');

interface MatchParams {
    prefix: string;
}

interface Props extends WithStyles<appStyle>, RouteComponentProps<MatchParams> {
    authStore: AuthStore;
    securityStore?: SecurityStore;
    userProfileStore: UserProfileStore;
    settingStore: SettingStore;
}

@inject('authStore')
@inject('securityStore')
@inject('userProfileStore')
@inject('settingStore')
@observer
export class Layout extends React.Component<Props, {}> {
    @observable private isMobileOpen = false;

    public componentDidMount = async (): Promise<void> => {
        await this.props.securityStore!.loadUserPermissions();
        window.addEventListener('resize', this.resizeFunction);
    };

    public componentWillUnmount(): void {
        window.removeEventListener('resize', this.resizeFunction);
    }

    // tslint:disable-next-line:no-any
    public componentDidUpdate(e: any): void {
        if (e.history.location.pathname !== e.location.pathname) {
            if (this.isMobileOpen) {
                this.handleCloseMobile();
            }
        }
    }

    public render(): JSX.Element | null {
        if (!this.props.securityStore!.permissionsLoaded) {
            return null;
        }

        const { classes } = this.props;
        const mainPanel = classNames(
            classes.mainPanel,
            {
                [classes.mainPanelSidebarMini]: this.props.settingStore.miniActive,
            },
            'site-container'
        );

        return (
            <div className={classes.wrapper}>
                <Sidebar
                    routes={this.PermissionRoutesWithTenantPrefix().filter((x) => x.renderInSidebar)}
                    logoText='Inventory'
                    logo={<Icon size={50} icon={IconResources.WebsiteLogo} />}
                    image={image}
                    handleDrawerToggle={this.handleDrawerToggle}
                    open={this.isMobileOpen}
                    color='blue'
                    bgColor='black'
                    miniActive={this.props.settingStore.miniActive}
                    location={this.props.location}
                    userAvatarUrl={this.props.userProfileStore!.userAvatarUrl}
                    username={this.props.userProfileStore!.userName}
                    settingsRoutes={this.PermissionRoutesWithTenantPrefix().filter((x) => x.settingsRoute)}
                    showSettings={true}
                    settingFooter={<SettingFooter key='setting-footer' />}
                />
                <div id='site-container-id' className={mainPanel}>
                    <Header
                        handleDrawerToggle={this.handleDrawerToggle}
                        authStore={this.props.authStore}
                        userAvatarUrl={this.props.userProfileStore!.userAvatarUrl}
                        headerMainText={this.props.userProfileStore!.userName}
                        headerSubText={this.props.securityStore!.selectedTenant!.friendlyName}
                    >
                        <TenantsSelect {...this.props} className='tenants-select-menu-item' />
                    </Header>

                    <div className='site-content'>
                        <SwitchRoutes routes={MainRoutes} location={this.props.location || undefined} />
                    </div>
                </div>
            </div>
        );
    }

    private PermissionRoutesWithTenantPrefix(): RouteModel[] {
        const tenantPrefix = this.props.securityStore!.getTenantPrefixFromCookieTenantName!;
        const result: RouteModel[] = this.getRoutesByPermissions().map((a) => ({ ...a })); // Deep copy is necessary here.

        result.forEach((route) => {
            this.replaceTenantPrefix(route, tenantPrefix);
            if (route.views && route.views.length > 0) {
                route.views!.forEach((r) => this.replaceTenantPrefix(r, tenantPrefix));
            }
        });
        return result;
    }

    private replaceTenantPrefix(route: RouteModel, tenantPrefix: string): void {
        route.path = route.path.replace(':prefix', tenantPrefix);
        if (route.pathTo) {
            route.pathTo = route.pathTo.replace(':prefix', tenantPrefix);
        }
    }

    private getRoutesByPermissions(): RouteModel[] {
        const result: RouteModel[] = [];
        MainRoutes.forEach((mainRoute: RouteModel) => {
            const clonedMainRoute = Object.assign({}, mainRoute); // Deep copy is necessary here.

            if (this.hasPermission(clonedMainRoute)) {
                if (clonedMainRoute.views && clonedMainRoute.views.length > 0) {
                    clonedMainRoute.views = clonedMainRoute.views!.filter((r: RouteModel) => this.hasPermission(r));
                    if (clonedMainRoute.views.length > 0) {
                        result.push(clonedMainRoute);
                    }
                } else {
                    result.push(clonedMainRoute);
                }
            }
        });
        return result;
    }

    private hasPermission(route: RouteModel): boolean {
        return this.props.securityStore!.hasPermission(route.permission) || route.permission === Permissions.None;
    }

    @action private handleDrawerToggle = (): void => {
        this.isMobileOpen = !this.isMobileOpen;
    };

    @action private resizeFunction = (): void => {
        if (window.innerWidth >= 960) {
            this.handleCloseMobile();
        }
    };

    @action private handleCloseMobile = (): void => {
        this.isMobileOpen = false;
    };
}

const StyledLayout = withStyles(appStyle)(Layout);
// tslint:disable-next-line:no-any
const WrappedLayout = withRouter<any, any>(StyledLayout);
export default WrappedLayout;
