import { useMediaQuery } from '@material-ui/core';
import json2mq from 'json2mq';

export enum DisplayMode {
    FullView,
    ConstrainedLayout,
}

export const useDisplayMode = () => {
    const matches = useMediaQuery(
        json2mq({
            minWidth: 1700,
        })
    );
    return { displayMode: matches ? DisplayMode.FullView : DisplayMode.ConstrainedLayout };
};
