import { observable, action, runInAction } from 'mobx';
import { PermissionResponse, UserSettingsResponse, NameValueResponse } from '@vulcan/security-api-client/lib/models';
import { Permissions } from './Permissions';
import { BaseStore } from '../Infrastructure';
import * as Cookies from 'js-cookie';
import SettingsService from '../Middleware/SettingsService';
import StringResources from '../../Shared/Constants/StringResources';
import UserSettingModel from './UserSettingModel';
import { AuthStore } from '.';

export class SecurityStore extends BaseStore {
    @observable public permissionsLoaded = false;
    @observable public permissions: PermissionResponse[] = [];

    @observable public loadingTenants = false;
    @observable public userTenants: UserSettingModel[] = [];
    @observable public selectUserTenantName = Cookies.get('tenant') || '';

    private authStore!: AuthStore;

    @action public init = async (authStore: AuthStore): Promise<void> => {
        const self = this;
        self.authStore = authStore;
        self.loadingTenants = true;

        this.setIsLoading(true);
        const allTenants = await self.getUserTenants();
        this.setIsLoading(false);

        const filteredTenants = this.filterTenants(allTenants);

        runInAction(() => {
            self.userTenants.length = 0;
            self.userTenants.push(...filteredTenants);
            self.loadingTenants = false;
            if (self.userTenants.length === 1) {
                // When there is only one tenant - select it automatically
                // - no need to ask the user to select a tenant.
                self.setSelectedTenant(self.userTenants[0].tenantName);
            } else if (self.userTenants.length > 1 && self.isTenantSelected) {
                // If there is more than 1 tenant available, only handle the case when the tenant is selected.
                // if the tenant is not selected, TenantsList modal will be shown to ask
                // the user to select a tenant.
                self.populateTenantsUrls(self.userTenants);
            }
        });
    };

    public get isTenantSelected(): boolean {
        return this.selectUserTenantName !== undefined;
    }

    private getUserTenants = async (): Promise<UserSettingModel[]> => {
        let result: UserSettingModel[] = [];
        try {
            const security = await this.getSecurityClient();
            const user = this.authStore.getUser;
            if (!user) {
                throw new Error(StringResources.NoAuthToken);
            }
            const userObjectId = user.profile.oid;
            const response = await security.users.getUserSettings(userObjectId);
            result = JSON.parse(response._response.bodyAsText) as UserSettingModel[];
        } catch (e) {
            result = [];
            this.log(StringResources.ErrorAcquiringUserTenants, e as any);
        }
        return result;
    };

    private filterTenants(allTenants: UserSettingModel[]): UserSettingModel[] {
        const filteredTenants: UserSettingModel[] = [];
        const necessaryUrls: string[] = [
            window.appSettings.securityApiUrl,
            window.appSettings.inventoryApiUrl
        ];

        allTenants.forEach((t: UserSettingModel) => {
            const allSettingsName = t.settings.map((setting: NameValueResponse) => setting.name.toLowerCase());
            // Detected that if this tenant including all url setting for this website.
            const hasSettings = necessaryUrls.every(url => allSettingsName.includes(url.toLowerCase()));
            if (hasSettings) {
                const websitePathPrefixSetting = t.settings.find((setting: NameValueResponse) => setting.name.toLowerCase() === 'websitepathprefix');
                if (websitePathPrefixSetting !== undefined) {
                    t.pathPrefix = websitePathPrefixSetting.value || '';
                }

                filteredTenants.push(t);
            }
        });

        return filteredTenants;
    }

    public get selectedTenant(): UserSettingModel | undefined {
        return this.userTenants.find((x) => x.tenantName === this.selectUserTenantName);
    }

    public get getTenantPrefixFromCookieTenantName(): string | undefined {
        return this.selectedTenant ? this.selectedTenant.pathPrefix : undefined;
    }

    public getTenantNameByTenantPrefix(paramTenantPrefix: string): string | undefined {
        const matchedTenant = this.userTenants.find((t) => t.pathPrefix === paramTenantPrefix);
        return matchedTenant ? matchedTenant.tenantName : undefined;
    }

    @action public setSelectedTenant = (newTenant: string): void => {
        Cookies.set('tenant', newTenant);
        this.selectUserTenantName = newTenant;
        this.populateTenantsUrls(this.userTenants);
    };

    private populateTenantsUrls(userTenants: UserSettingsResponse[]): void {
        const tenant = userTenants.find((x) => x.tenantName === this.selectUserTenantName);
        if (tenant) {
            SettingsService.tenantsUrls.clear();
            tenant.settings.forEach((s: NameValueResponse) => {
                SettingsService.tenantsUrls.set(s.name, s.value!);
            });
        }
    }

    @action public async loadUserPermissions(): Promise<void> {
        this.permissionsLoaded = false;
        const userPermissions = await this.getUserPermissions();

        runInAction(() => {
            this.permissionsLoaded = true;
            this.permissions = userPermissions;
        });
    }

    private getUserPermissions = async (): Promise<PermissionResponse[]> => {
        let result: PermissionResponse[] = [];
        try {
            const security = await this.getSecurityClient();
            const response = await security.authenticatedUser.getPermissions();
            result = JSON.parse(response._response.bodyAsText) as PermissionResponse[];
        } catch (e) {
            result = [];    
            this.log(StringResources.ErrorAcquiringUserTenants, e as any);
        }

        return result;
    };

    public hasPermission(permission: Permissions): boolean {
        const res = this.permissions.filter((p) => p.name.toLowerCase() === permission.toLowerCase());
        return res.length !== 0;
    }
}

export default SecurityStore;
