import { HttpService } from '../Middleware/HttpService';
import { HttpMethod } from '../Middleware/HttpMethod';
import { BaseStore } from '../Infrastructure/BaseStore';
import { AppSettings } from '../Infrastructure/AppSettings';
import { observable, action, runInAction } from 'mobx';
import StringResources from '../../Shared/Constants/StringResources';
import { AuthStore } from '.';

export class UserProfileStore extends BaseStore {
    @observable public userAvatarUrl = '';
    private authStore: AuthStore;
    private appSettings: AppSettings;

    constructor(authStore: AuthStore) {
        super();
        this.authStore = authStore;
        this.appSettings = new AppSettings();
    }

    // TODO: TEST moving this to constructor or accessing window object directly
    @action public init(): void {
        this.appSettings = window.appSettings;
    }

    public get userName(): string {
        return this.authStore && this.authStore.getAuthContext() ? this.authStore.getAuthContext().getCachedUser().profile.name : '';
    }

    @action public async getUserProfilePhoto(): Promise<void> {
        await this.authStore.acquireGraphApiToken(
            async (error: Error, token: string): Promise<void> => {
                if (error || !token) {
                    this.log(StringResources.ErrorGettingProfilePhoto, error);
                }

                if (token) {
                    const requestOptions = {
                        method: HttpMethod.Get,
                        headers: { authorization: 'Bearer ' + token, Accept: 'image/jpeg' },
                    };

                    let validatedResponse: Response;

                    const response = await fetch(`${this.appSettings.graphApiUrl}/beta/me/photo/$value`, requestOptions);

                    try {
                        validatedResponse = await HttpService.checkStatus(response);
                        const buffer = await validatedResponse.arrayBuffer();
                        const base64Flag = 'data:image/jpeg;base64,';
                        const imageString = this.arrayBufferToBase64(buffer);
                        runInAction(() => {
                            this.userAvatarUrl = base64Flag + imageString;
                        });
                    } catch (e) {
                        this.log(StringResources.ErrorGettingProfilePhoto, e);
                    }
                }
            }
        );
    }

    private arrayBufferToBase64(buffer: ArrayBuffer): string {
        let binary = '';
        const bytes = [].slice.call(new Uint8Array(buffer));

        bytes.forEach((b) => (binary += String.fromCharCode(b)));

        return window.btoa(binary);
    }
}

export default UserProfileStore;
