import * as React from 'react';
import { observer, inject } from 'mobx-react';
import UpdateProductPriceMaintenanceStore from './UpdateProductPriceMaintenanceStore';
import { RouteComponentProps } from 'react-router-dom';
import ReactTable, { ReactTableDefaults, CellInfo, SortingRule } from 'react-table';
import { toCurrency } from 'src/Shared/Utils';
import { Icon } from '@vulcan/vulcan-materialui-theme';
import ValidationResult from './Models/ValidationResult';

interface Props extends RouteComponentProps {
    updateProductPriceMaintenanceStore?: UpdateProductPriceMaintenanceStore;
}

@inject('updateProductPriceMaintenanceStore')
@observer
export class UpdateProductPriceMaintenanceTable extends React.Component<Props, {}> {
    private handlePageChange = (page: number): void => {
        this.props.updateProductPriceMaintenanceStore!.setPage(page);
    };

    private handleSortingChange = (newSorted: SortingRule[]): void => {
        this.props.updateProductPriceMaintenanceStore!.setSorting(newSorted);
        this.props.updateProductPriceMaintenanceStore!.setPage(0);
    };

    public render(): JSX.Element {
        const columns = [
            {
                id: 'product-code',
                Header: 'Product Code',
                accessor: 'productCode',
                sortable: true,
                width: 250,
            },
            {
                id: 'product-group',
                Header: 'Group',
                accessor: 'productGroup',
                sortable: true,
                width: 250,
            },
            {
                id: 'warehouseCode',
                Header: 'Warehouse',
                accessor: 'warehouseCode',
                sortable: true,
                width: 200,
            },
            {
                id: 'class',
                Header: 'Class',
                accessor: 'stockClass',
                width: 70,
                sortable: true,
                Cell: (cell: CellInfo): JSX.Element => (
                    <div className='product-class-cell' style={{ backgroundColor: cell.original.stockClassColour }}>
                        {cell.value}
                    </div>
                ),
            },
            {
                id: 'new-standard-cost',
                Header: 'New Std Cost',
                accessor: 'newStandardCost',
                sortable: true,
                width: 200,
                Cell: (cell: CellInfo): string => toCurrency(cell.value),
            },
            {
                id: 'new-list-price',
                Header: 'New List Price',
                accessor: 'newListPrice',
                sortable: true,
                width: 200,
                Cell: (cell: CellInfo): string => toCurrency(cell.value),
            },
            {
                id: 'validation',
                Header: 'Message',
                accessor: 'validationResult',
                sortable: true,
                width: 500,
                sortMethod: (a: ValidationResult, b: ValidationResult): number => {
                    return a.type - b.type;
                },
                Cell: (cell: CellInfo): JSX.Element => {
                    const value = cell.value as ValidationResult;
                    return (
                        <>
                            <div className={`validation-${value.color}`}>
                                <Icon icon={value.icon} />
                            </div>
                            {' ' + value.message}
                        </>
                    );
                },
            },
        ];
        return (
            <ReactTable
                data={this.props.updateProductPriceMaintenanceStore!.tableDataFiltered.slice()}
                columns={columns}
                column={{
                    ...ReactTableDefaults.column,
                    style: { whiteSpace: 'unset' },
                }}
                className='react-table -striped -highlight'
                loading={this.props.updateProductPriceMaintenanceStore!.tableDataLoading}
                minRows={14}
                pageSize={14}
                page={this.props.updateProductPriceMaintenanceStore!.pageNumber}
                onPageChange={this.handlePageChange}
                onSortedChange={this.handleSortingChange}
                filterable={false}
                sortable={false}
                sorted={this.props.updateProductPriceMaintenanceStore!.sorting}
                showPageJump={false}
                showPageSizeOptions={false}
                showPaginationTop={false}
                showPaginationBottom={true}
                showPagination={true}
                collapseOnDataChange={false}
                collapseOnSortingChange={false}
                collapseOnPageChange={false}
                resizable={false}
            />
        );
    }
}
export default UpdateProductPriceMaintenanceTable;
