import { BaseStore } from 'src/App/Infrastructure';
import { observable, action, runInAction, computed, toJS } from 'mobx';
import { OptionModel } from '@vulcan/vulcan-materialui-theme/build/components';
import { uniqBy } from 'lodash';
import AlertType from 'src/Shared/Alert/AlertType';
import { StringResources } from 'src/Shared/Constants';
import WarehouseModel from './Models/WarehouseModel';
import ViewProductPriceMaintenanceTableDataRowModel from 'src/PriceMaintenance/View/Models/ViewProductPriceMaintenanceTableDataRowModel';
import { SortingRule } from 'react-table';
import {
    StockLevelForPriceMaintenanceResponse,
    StockFilterResponse,
    StockGetStockFiltersResponse,
    StockProductGroupFilterResponse,
    StockProductCategoryFilterResponse,
    StockClassificationFilterResponse,
    StockLevelForPriceMaintenanceRequest,
    StockWarehousesFilterResponse,
} from '@vulcan/inventory-api-client/lib/models';
import { localeCompare } from 'src/Shared/Utils';

export class ViewProductPriceMaintenanceStore extends BaseStore {
    @observable public loading = false;
    @observable private allStockFilters: StockFilterResponse | undefined;

    @observable public selectedStockWarehouseCodes: string[] = [];
    @observable private selectedProductCategoryIds: number[] = [];
    @observable private selectedProductGroupIds: number[] = [];
    @observable private selectedStockClassificationIds: number[] = [];

    @observable public tableDataLoading = false;
    @observable public tableData: ViewProductPriceMaintenanceTableDataRowModel[] = [];
    @observable public pageNumber = 0;
    @observable public sorting: SortingRule[] = [{ id: 'product-code', desc: false }];

    @action public setPage(page: number): void {
        this.pageNumber = page;
    }

    @action public setSorting(sorting: SortingRule[]): void {
        this.sorting = sorting;
    }

    @action public async init(): Promise<void> {
        this.loading = true;
        const allStockFilters = await this.getAllStockFilters();

        runInAction(() => {
            this.allStockFilters = allStockFilters;
            this.loading = false;
        });
    }

    // Filters
    private async getAllStockFilters(): Promise<StockFilterResponse | undefined> {
        try {
            const inventoryClient = await this.getInventoryClient();
            const response = await inventoryClient.stock.getStockFilters();
            return JSON.parse(response._response.bodyAsText) as StockGetStockFiltersResponse;
        } catch (e) {
            this.log(`${StringResources.ErrorLoadingStockFiltersException}`, e);
            this.showAlert(AlertType.Danger, StringResources.ErrorLoadingStockFiltersException);
            return undefined;
        }
    }

    @computed public get allWarehouseDataSource(): OptionModel[] {
        if (this.allStockFilters) {
            const allOptions = this.allStockFilters.allWarehouses.sort(WarehouseModel.compareFn).map((w: StockWarehousesFilterResponse) => {
                return new OptionModel({
                    value: `${w.warehouseId}`,
                    label: `${w.warehouseCode}`,
                });
            });

            return allOptions;
        }
        return [];
    }

    @computed public get allProductCategoryDataSource(): OptionModel[] {
        if (this.allStockFilters) {
            const productCategories =
                this.selectedStockWarehouseCodes.length > 0
                    ? this.allStockFilters.allProductCategories.filter((pc) => this.selectedStockWarehouseCodes.filter((wc) => pc.warehouseCodes.includes(wc)).length > 0)
                    : this.allStockFilters.allProductCategories;
            const allOptions = productCategories
                .sort((a: StockProductCategoryFilterResponse, b: StockProductCategoryFilterResponse): number => localeCompare(a.name!, b.name!))
                .map(
                    (pc) =>
                        new OptionModel({
                            value: `${pc.productCategoryId}`,
                            label: `${pc.name}`,
                        })
                );

            return allOptions;
        }
        return [];
    }

    @computed public get allProductGroupDataSource(): OptionModel[] {
        if (this.allStockFilters) {
            const productGroups = this.allStockFilters.allProductGroups
                .filter(
                    (pc: StockProductGroupFilterResponse) =>
                        this.selectedStockWarehouseCodes.length === 0 || this.selectedStockWarehouseCodes.filter((wc: string) => pc.warehouseCodes.includes(wc)).length > 0
                )
                .filter(
                    (pc: StockProductGroupFilterResponse) =>
                        !this.selectedProductCategoryIds || this.selectedProductCategoryIds.length === 0 || this.selectedProductCategoryIds.includes(pc.productCategoryId)
                );

            const allOptions = productGroups
                .sort((a: StockProductGroupFilterResponse, b: StockProductGroupFilterResponse): number => localeCompare(a.name!, b.name!))
                .map(
                    (pg: StockProductGroupFilterResponse) =>
                        new OptionModel({
                            value: `${pg.productGroupId}`,
                            label: `${pg.name}`,
                        })
                );

            return allOptions;
        }
        return [];
    }

    @computed public get allStockClassificationDataSource(): OptionModel[] {
        if (this.allStockFilters) {
            const stockClassifications = this.allStockFilters.allStockClassifications
                // Filter by stock warehouses
                .filter(
                    (sc: StockClassificationFilterResponse) =>
                        this.selectedStockWarehouseCodes.length === 0 || this.selectedStockWarehouseCodes.filter((wc: string) => sc.warehouseCodes.includes(wc)).length > 0
                )
                // Filter by product categories
                .filter(
                    (sc: StockClassificationFilterResponse) =>
                        !this.selectedProductCategoryIds ||
                        this.selectedProductCategoryIds.length === 0 ||
                        this.selectedProductCategoryIds.filter((pc: number) => sc.productCategories.includes(pc)).length > 0
                )
                // Filter by product groups
                .filter(
                    (sc: StockClassificationFilterResponse) =>
                        !this.selectedProductGroupIds ||
                        this.selectedProductGroupIds.length === 0 ||
                        this.selectedProductGroupIds.filter((pg: number) => sc.productGroups.includes(pg)).length > 0
                )
                // Filter by product category/group and warehouse
                .filter((sc: StockClassificationFilterResponse) => {
                    const hasSelectedWarehouseCodes = this.selectedStockWarehouseCodes && this.selectedStockWarehouseCodes.length > 0;
                    const hasSelectedProductCategories = this.selectedProductCategoryIds && this.selectedProductCategoryIds.length > 0;
                    const hasSelectedProductGroupIds = this.selectedProductGroupIds && this.selectedProductGroupIds.length > 0;

                    // Only filter when user has selected a warehouse AND a product category/group
                    if (hasSelectedWarehouseCodes && (hasSelectedProductCategories || hasSelectedProductGroupIds)) {
                        let productGroupIds = this.selectedProductGroupIds;

                        // Calculate all product groups for the selected product categories.
                        // This handles when user has no filter for product groups. I.e. "All Product Groups"
                        if (!hasSelectedProductGroupIds && hasSelectedProductCategories) {
                            productGroupIds = this.allStockFilters!.allProductGroups.filter((productGroup: StockProductGroupFilterResponse) =>
                                this.selectedProductCategoryIds!.includes(productGroup.productCategoryId)
                            ).map((productGroup: StockProductGroupFilterResponse) => productGroup.productGroupId);
                        }

                        // Decide if this stock classification should be shown based on warehouse and product group.
                        for (const warehouseCode of toJS(this.selectedStockWarehouseCodes)) {
                            const warehouseCodeParameter = this.getParameterNameCaseInsensitive(sc.productGroupByWarehouse, warehouseCode);
                            if (warehouseCodeParameter && sc.productGroupByWarehouse[warehouseCodeParameter]) {
                                for (const productGroupId of sc.productGroupByWarehouse[warehouseCodeParameter]) {
                                    if (productGroupIds!.includes(productGroupId)) {
                                        return true; // Stock classification should be shown.
                                    }
                                }
                            }
                        }
                        return false; // Stock classification should not be shown.
                    }
                    return true; // Filter nothing as user has not selected a warehouse AND a product category/group.
                });

            const allOptions = uniqBy(stockClassifications, (stockClassification) => stockClassification.name)
                .sort((a: StockClassificationFilterResponse, b: StockClassificationFilterResponse): number => localeCompare(a.name!, b.name!))
                .map(
                    (sc: StockClassificationFilterResponse) =>
                        new OptionModel({
                            value: `${sc.stockClassificationId}`,
                            label: `${sc.name}`,
                        })
                );

            return allOptions;
        }
        return [];
    }

    @action public async updateTableControl(): Promise<void> {
        try {
            this.tableDataLoading = true;
            const tableData = await this.getTableData();

            runInAction(() => {
                this.tableData = tableData;
                this.pageNumber = 0;
                this.tableDataLoading = false;
            });
        } catch (e) {
            this.log(`${StringResources.ErrorLoadingPriceMaintenanceTableException}`, e);
            this.showAlert(AlertType.Danger, StringResources.ErrorLoadingPriceMaintenanceTableException);
        } finally {
            runInAction(() => {
                this.tableDataLoading = false;
            });
        }
    }

    private getParameterNameCaseInsensitive(object: {}, key: string): string | undefined {
        return Object.keys(object).find((k) => k.toLowerCase() === key.toLowerCase());
    }

    private async getTableData(): Promise<ViewProductPriceMaintenanceTableDataRowModel[]> {
        const response = await this.getStockLevelForPriceMaintenance();
        return response.map(
            (item) =>
                new ViewProductPriceMaintenanceTableDataRowModel({
                    productCode: item.productCode,
                    productCategory: item.productCategoryName,
                    productGroup: item.productGroupName,
                    warehouseCode: item.warehouseCode,
                    stockClass: item.stockClassificationCode,
                    stockClassColour: item.stockClassificationColour,
                    availableStock: item.availableStock,
                    eachWeight: item.pieceWeightKg,
                    eachWAC: item.pieceWeightedAverageCost,
                    eachLLC: item.pieceLastLandedCost,
                    eachStandardCost: item.pieceStandardCost,
                    eachListPrice: item.pieceListPrice,
                })
        );
    }

    private async getStockLevelForPriceMaintenance(): Promise<StockLevelForPriceMaintenanceResponse[]> {
        let result: StockLevelForPriceMaintenanceResponse[] = [];
        try {
            const inventoryClient = await this.getInventoryClient();

            const request: StockLevelForPriceMaintenanceRequest = {
                warehouseCodes: this.selectedStockWarehouseCodes.length > 0 ? toJS(this.selectedStockWarehouseCodes) : undefined,
                productCategoryIds: this.selectedProductCategoryIds.length > 0 ? toJS(this.selectedProductCategoryIds) : undefined,
                productGroupIds: this.selectedProductGroupIds.length > 0 ? toJS(this.selectedProductGroupIds) : undefined,
                stockClassificationIds: this.selectedStockClassificationIds.length > 0 ? toJS(this.selectedStockClassificationIds) : undefined,
            };

            const response = await inventoryClient.priceMaintenance.getStockLevelForPriceMaintenance(request);
            result = JSON.parse(response._response.bodyAsText) as StockLevelForPriceMaintenanceResponse[];
        } catch (e) {
            this.log(`${StringResources.ErrorLoadingPriceMaintenanceTableException}`, e);
            this.showAlert(AlertType.Danger, StringResources.ErrorLoadingPriceMaintenanceTableException);
        } finally {
            return result;
        }
    }

    @action public selectedStockWarehouseChanged(selectedWarehouses: OptionModel[]): void {
        this.selectedStockWarehouseCodes = selectedWarehouses.map((w) => w.label);
        this.clearSelectedProductCategoryIds();
        this.clearSelectedProductGroupIds();
        this.clearSelectedStockClassificationIds();
    }

    @action public selectedProductCategoryChanged(selectedCategories: OptionModel[]): void {
        this.selectedProductCategoryIds = selectedCategories.map((c) => Number(c.value));
        this.clearSelectedProductGroupIds();
        this.clearSelectedStockClassificationIds();
    }

    @action public selectedProductGroupChanged(selectedGroups: OptionModel[]): void {
        this.selectedProductGroupIds = selectedGroups.map((g) => Number(g.value));
        this.clearSelectedStockClassificationIds();
    }

    @action public selectedStockClassificationChanged(selectedClassifications: OptionModel[]): void {
        this.selectedStockClassificationIds = selectedClassifications.map((c) => Number(c.value));
    }

    private clearSelectedProductCategoryIds(): void {
        if (this.selectedProductCategoryLabels.length === 0) {
            this.selectedProductCategoryIds = [];
        }
    }

    private clearSelectedProductGroupIds(): void {
        if (this.selectedProductGroupLabels.length === 0) {
            this.selectedProductGroupIds = [];
        }
    }
    private clearSelectedStockClassificationIds(): void {
        if (this.selectedStockClassificationLabels.length === 0) {
            this.selectedStockClassificationIds = [];
        }
    }

    public get selectedProductCategoryLabels(): string[] {
        return this.allProductCategoryDataSource.filter((item) => this.selectedProductCategoryIds.includes(Number(item.value))).map((item) => item.label);
    }

    public get selectedProductGroupLabels(): string[] {
        return this.allProductGroupDataSource.filter((item) => this.selectedProductGroupIds.includes(Number(item.value))).map((item) => item.label);
    }

    public get selectedStockClassificationLabels(): string[] {
        return this.allStockClassificationDataSource.filter((item) => this.selectedStockClassificationIds.includes(Number(item.value))).map((item) => item.label);
    }
}

export default ViewProductPriceMaintenanceStore;
