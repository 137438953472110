import * as React from 'react';
import { observer, inject } from 'mobx-react';
import ViewProductPriceMaintenanceStore from './ViewProductPriceMaintenanceStore';
import { RouteComponentProps } from 'react-router-dom';
import ViewProductPriceMaintenanceFilter from './ViewProductPriceMaintenanceFilter';
import { GridContainer, GridItem, Icon, Card, CardHeader, CardBody, CardIcon, NotAuthorised, Snackbar } from '@vulcan/vulcan-materialui-theme';
import { IconResources } from 'src/Shared/Constants';
import ViewProductPriceMaintenanceTable from 'src/PriceMaintenance/View/ViewProductPriceMaintenanceTable';
import ViewProductPriceMaintenanceExport from 'src/PriceMaintenance/View/ViewProductPriceMaintenanceExport';
import { SecurityStore } from 'src/App/Security';
import Permissions from 'src/App/Security/Permissions';

interface Props extends RouteComponentProps {
    viewProductPriceMaintenanceStore?: ViewProductPriceMaintenanceStore;
    securityStore?: SecurityStore;
}

@inject('viewProductPriceMaintenanceStore')
@inject('securityStore')
@observer
export class ViewProductPriceMaintenanceLayout extends React.Component<Props, {}> {
    private securityPolicy: Record<string, boolean>;

    public async componentDidMount(): Promise<void> {
        await this.props.viewProductPriceMaintenanceStore!.init();
    }

    constructor(props: Props) {
        super(props);
        this.securityPolicy = {
            canViewStock: this.props.securityStore!.hasPermission(Permissions.StockView),
        };
    }

    public render(): React.ReactNode {
        if (!this.securityPolicy.canViewStock) {
            return <NotAuthorised />;
        }

        return (
            <div className='container'>
                {/* Filter */}
                <ViewProductPriceMaintenanceFilter {...this.props} />

                {/* Table */}
                <GridContainer>
                    <GridItem xs={12}>
                        <Card className='card'>
                            <CardHeader color='primary' icon={true} className='card-header'>
                                <CardIcon className='card-icon' color='warning'>
                                    <Icon icon={IconResources.Pencil} />
                                </CardIcon>
                                <div className='card-header-section'>
                                    <h4 className='card-title'>View Prices</h4>
                                    <ViewProductPriceMaintenanceExport />
                                </div>
                            </CardHeader>
                            <CardBody>
                                <ViewProductPriceMaintenanceTable />
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>

                <Snackbar
                    place='br'
                    color={this.props.viewProductPriceMaintenanceStore!.alertType}
                    icon={(): JSX.Element => <Icon icon={IconResources.AddAlert} />}
                    message={this.props.viewProductPriceMaintenanceStore!.alertMessage}
                    open={this.props.viewProductPriceMaintenanceStore!.alert}
                    closeNotification={(): void => this.props.viewProductPriceMaintenanceStore!.hideAlert()}
                    close={true}
                />
            </div>
        );
    }
}
export default ViewProductPriceMaintenanceLayout;
