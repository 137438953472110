import { BaseStore } from 'src/App/Infrastructure';
import { action, computed, observable, runInAction } from 'mobx';
import XLSX from 'xlsx';
import UpdateProductPriceMaintenanceTableDataRowModel from './Models/UpdateProductPriceMaintenanceTableDataRowModel';
import UpdateProductPriceMaintenanceImportDataRowModel from './Models/UpdateProductPriceMaintenanceImportDataRowModel';
import { StringResources, IconResources } from 'src/Shared/Constants';
import AlertType from 'src/Shared/Alert/AlertType';
import ValidationResultType from './Models/ValidationResultType';
import ValidationResult from 'src/PriceMaintenance/Update/Models/ValidationResult';
import {
    StockPriceUpdateListRequest,
    StockPriceUpdateRequest,
    ValidatePriceMaintenanceRequest,
    ValidatePriceMaintenanceLineRequest,
} from '@vulcan/inventory-api-client/esm/models';
import { SortingRule } from 'react-table';
import { toFixedNumber, toFixedNumberString } from 'src/Shared/Utils';

export class UpdateProductPriceMaintenanceStore extends BaseStore {
    @observable public pageNumber = 0;
    @observable public errorCount = 0;
    @observable public warningCount = 0;
    @observable public successCount = 0;
    @observable public isSubmitAlertOpen = false;
    @observable public tooltipText = '';
    @observable public fileData: UpdateProductPriceMaintenanceTableDataRowModel[] = [];
    @observable public tableData: UpdateProductPriceMaintenanceTableDataRowModel[] = [];
    @observable public isDisabledSubmitButton = true;
    @observable public showError = true;
    @observable public showWarning = true;
    @observable public showSuccess = true;
    @observable public tableDataLoading = false;
    @computed public get tableDataFiltered(): UpdateProductPriceMaintenanceTableDataRowModel[] {
        return this.tableData.filter(
            (item) =>
                (item.validationResult.type === ValidationResultType.Error && this.showError) ||
                (item.validationResult.type === ValidationResultType.Warning && this.showWarning) ||
                (item.validationResult.type === ValidationResultType.Success && this.showSuccess)
        );
    }
    @observable public sorting: SortingRule[] = [
        { id: 'validation', desc: false },
        { id: 'product-code', desc: false },
    ];

    @action public toggleError = (): void => {
        this.showError = !this.showError;
        this.setPage(0);
    };

    @action public toggleWarning = (): void => {
        this.showWarning = !this.showWarning;
        this.setPage(0);
    };

    @action public toggleSuccess = (): void => {
        this.showSuccess = !this.showSuccess;
        this.setPage(0);
    };

    @action public showPriceSubmitAlert = (): void => {
        this.isSubmitAlertOpen = true;
    };

    @action public hidePriceSubmitAlert = (): void => {
        this.isSubmitAlertOpen = false;
    };

    @action public setPage(page: number): void {
        this.pageNumber = page;
    }

    @action public setSorting(sorting: SortingRule[]): void {
        this.sorting = sorting;
    }

    @action private resetValues(): void {
        runInAction(() => {
            this.tableData = [];
            this.setPage(0);
            this.errorCount = 0;
            this.warningCount = 0;
            this.successCount = 0;
            this.isDisabledSubmitButton = true;
            this.tableDataLoading = false;
        });
    }

    @action public submit = async (): Promise<void> => {
        this.hidePriceSubmitAlert();
        const itemsToSubmit = this.tableData;
        if (itemsToSubmit && this.errorCount === 0) {
            try {
                runInAction(() => {
                    this.tableDataLoading = true;
                });
                const inventoryClient = await this.getInventoryClient();
                const entries: StockPriceUpdateRequest[] = itemsToSubmit.map(
                    (item) =>
                        ({
                            productCode: item.productCode,
                            warehouseCode: item.warehouseCode,
                            newPieceStandardCost: item.newStandardCost,
                            newPieceListPrice: item.newListPrice,
                        } as StockPriceUpdateRequest)
                );
                const request: StockPriceUpdateListRequest = {
                    entries: entries,
                };
                await inventoryClient.priceMaintenance.updateStockProfilePrice(request);
                this.showAlert(AlertType.Success, StringResources.SuccessSubmittingNewPrices);
                this.resetValues();
            } catch (e) {
                this.log(StringResources.ErrorSubmittingNewPricesException, e);
                this.showAlert(AlertType.Danger, StringResources.ErrorSubmittingNewPricesException);
            } finally {
                runInAction(() => {
                    this.tableDataLoading = false;
                });
            }
        }
    };

    @action public async import(files: FileList): Promise<void> {
        this.setPage(0);
        runInAction(() => {
            this.tableDataLoading = true;
        });
        const reader = new FileReader();
        reader.onload = async (e: ProgressEvent<FileReader>): Promise<void> => {
            const data = XLSX.read(e.target!.result, { type: 'binary' });
            const workSheet = data.Sheets[data.SheetNames[0]]; // first worksheet
            const jsonData = XLSX.utils.sheet_to_json(workSheet);
            const importedData = jsonData.map(
                // tslint:disable-next-line:no-any
                (row: any) =>
                    new UpdateProductPriceMaintenanceImportDataRowModel({
                        productCode: row['Product Code'],
                        warehouseCode: row.Warehouse,
                        newListPrice: row['New List Price'],
                        newStandardCost: row['New Std Cost'],
                    })
            );
            const itemsToUpdate = importedData.filter((item: UpdateProductPriceMaintenanceImportDataRowModel) => item.newListPrice || item.newStandardCost);
            const validatedData = await this.validateImportedData(itemsToUpdate);
            if (validatedData.length !== 0) {
                runInAction(() => {
                    this.tableData = validatedData;
                    this.errorCount = validatedData.filter((x) => x.validationResult.type === ValidationResultType.Error).length;
                    this.warningCount = validatedData.filter((x) => x.validationResult.type === ValidationResultType.Warning).length;
                    this.successCount = validatedData.filter((x) => x.validationResult.type === ValidationResultType.Success).length;
                    this.isDisabledSubmitButton = this.errorCount !== 0;
                    this.tooltipText = this.errorCount === 0 ? '' : StringResources.TooltipFixErrorsBeforeSubmitting;
                    this.tableDataLoading = false;
                });
            } else {
                this.resetValues();
            }
        };
        reader.onerror = (e: ProgressEvent<FileReader>): void => {
            this.resetValues();
            this.log(`${StringResources.ErrorLoadingFileException}`, e.target!.error!);
            this.showAlert(AlertType.Danger, StringResources.ErrorLoadingFileException);
        };
        reader.readAsBinaryString(files[0]);
    }

    private async validateImportedData(importedData: UpdateProductPriceMaintenanceImportDataRowModel[]): Promise<UpdateProductPriceMaintenanceTableDataRowModel[]> {
        if (importedData) {
            try {
                const inventoryClient = await this.getInventoryClient();
                const lines: ValidatePriceMaintenanceLineRequest[] = importedData.map(
                    (item) =>
                        ({
                            productCode: item.productCode,
                            warehouseCode: item.warehouseCode,
                            standardCost: toFixedNumberString(item.newStandardCost, 2),
                            listPrice: toFixedNumberString(item.newListPrice, 2),
                        } as ValidatePriceMaintenanceLineRequest)
                );
                const request: ValidatePriceMaintenanceRequest = {
                    lines: lines,
                };
                const result = await inventoryClient.priceMaintenance.validatePriceMaintenanceLines(request);

                return result.map((data) => {
                    let validationResult = new ValidationResult();
                    if (data!.validationWarnings!.length !== 0) {
                        validationResult = new ValidationResult({
                            type: ValidationResultType.Warning,
                            message: data!.validationWarnings![0],
                            icon: IconResources.Warning,
                            color: 'warning',
                        });
                    } else if (data!.validationErrors!.length !== 0) {
                        validationResult = new ValidationResult({
                            type: ValidationResultType.Error,
                            message: data!.validationErrors![0],
                            icon: IconResources.Cross,
                            color: 'error',
                        });
                    }
                    return new UpdateProductPriceMaintenanceTableDataRowModel({
                        productCode: data.productCode,
                        productGroup: data.productGroupName,
                        warehouseCode: data.warehouseCode,
                        stockClass: data.stockClassificationCode,
                        stockClassColour: data.stockClassificationColour,
                        newStandardCost: toFixedNumber(data.standardCost, 2),
                        newListPrice: toFixedNumber(data.listPrice, 2),
                        validationResult: validationResult,
                    });
                });
            } catch (e) {
                this.log(StringResources.ErrorValidatingPricesException, e);
                this.showAlert(AlertType.Danger, StringResources.ErrorValidatingPricesException);
            }
        }
        return [];
    }
}
export default UpdateProductPriceMaintenanceStore;
