import { BaseStore } from '../Infrastructure';
import { observable, action, computed } from 'mobx';
import * as Cookies from 'js-cookie';

export class SettingStore extends BaseStore {
    // Be carful!, Javascript will do Boolean("false");  // == true
    @observable private _miniActive: boolean = Cookies.get('miniActive') ? Cookies.get('miniActive').toLowerCase() === 'true' : false;

    @computed get miniActive(): boolean {
        return this._miniActive;
    }

    @action public handelMiniModeToggle = (): void => {
        this._miniActive = !this._miniActive;
        Cookies.set('miniActive', this._miniActive, { expires: 7 /* days*/ });
    };

    public get CurrentYear(): number {
        return this.BrowserTodayDate.year();
    }
}

export default SettingStore;
