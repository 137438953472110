import React, { useCallback } from 'react';
import { ProductListItem } from '../utils/api/fetch-products';
import { DropDown } from './drop-down';
import { OptionModel } from '@vulcan/vulcan-materialui-theme/build/components';
import { useInventoryApi } from '../utils/api/use-inventory-api';
import { useNotifier } from 'src/Shared/Notification/notification-context';

export const ProductListDropDown = (props: { className: string; onProductSelectionChanged: (selectedProducts: string[]) => void }) => {
    const { onProductSelectionChanged, className } = props;
    const { fetchProductList } = useInventoryApi();
    const { raiseError } = useNotifier();
    const fetchProducts = useCallback(async () => {
        try {
            const products = await fetchProductList();
            return products.items;
        } catch {
            raiseError('There was an error trying to retrieve the product list. Please refresh your browser and try again.');
        }
        return [];
    }, [fetchProductList, raiseError]);
    const mapToOptionModel = useCallback((item: ProductListItem) => new OptionModel({ label: item.productCode, value: item.productCode }), []);
    return (
        <DropDown
            onSelectionChanged={onProductSelectionChanged}
            placeholderText='Search for a product'
            searchPromptText='Specify the first few characters of the product'
            noSearchResultFoundText='Cannot find the specified product'
            className={className}
            formLabel='Product'
            loadOptions={fetchProducts}
            mapToOptionModel={mapToOptionModel}
            fieldsToSearch={['productCode']}
        />
    );
};
