import * as React from 'react';
import { Icon } from '@vulcan/vulcan-materialui-theme';
import { RouteModel } from '.';
import { IconResources } from 'src/Shared/Constants';
import CreateProductLayout from 'src/CreateProduct/CreateProductLayout';
import Permissions from '../Security/Permissions';
import ConfigureAvailabilityLayout from 'src/ConfigureAvailability/ConfigureAvailabilityLayout';
import ViewProductPriceMaintenanceLayout from 'src/PriceMaintenance/View/ViewProductPriceMaintenanceLayout';
import UpdateProductPriceMaintenanceLayout from 'src/PriceMaintenance/Update/UpdateProductPriceMaintenanceLayout';
import { ActualCostAdjustmentLog } from 'src/ActualCostAdjustmentLog/actual-cost-adjustment-log';

export const MainRoutes: RouteModel[] = [
    {
        collapse: false,
        permission: Permissions.None,
        path: '/:prefix/createProduct',
        name: 'Create product form',
        icon: (): JSX.Element => <Icon size={30} icon={IconResources.Pencil} />,
        component: CreateProductLayout,
        redirect: false,
        renderInSidebar: false,
        settingsRoute: false,
    },
    {
        collapse: false,
        permission: Permissions.None,
        path: '/:prefix/drafts',
        name: 'Drafts',
        icon: (): JSX.Element => <Icon size={30} icon={IconResources.Draft} />,
        // component: CreateProductLayout,
        redirect: false,
        renderInSidebar: false,
        settingsRoute: false,
    },
    {
        collapse: false,
        permission: Permissions.None,
        path: '/:prefix/pending',
        name: 'Waiting for approval',
        icon: (): JSX.Element => <Icon size={30} icon={IconResources.Pending} />,
        // component: CreateProductLayout,
        redirect: false,
        renderInSidebar: false,
        settingsRoute: false,
    },
    {
        collapse: false,
        permission: Permissions.None,
        path: '/:prefix/approved',
        name: 'Approved',
        icon: (): JSX.Element => <Icon size={30} icon={IconResources.Approval} />,
        // component: CreateProductLayout,
        redirect: false,
        renderInSidebar: false,
        settingsRoute: false,
    },
    {
        collapse: false,
        permission: Permissions.None,
        path: '/',
        name: 'divider',
        divider: true,
        renderInSidebar: false,
        settingsRoute: false,
    },
    {
        collapse: false,
        permission: Permissions.None,
        path: '/:prefix/configureAvailability',
        name: 'Configure availability',
        icon: (): JSX.Element => <Icon size={30} icon={IconResources.Checkmark} />,
        component: ConfigureAvailabilityLayout,
        redirect: false,
        renderInSidebar: false,
        settingsRoute: false,
    },
    {
        collapse: true,
        state: 'openPriceMaintenance',
        permission: Permissions.StockView,
        path: '/:prefix/priceMaintenance',
        name: 'Price Maintenance',
        icon: (): JSX.Element => <Icon size={30} icon={IconResources.Pencil} />,
        redirect: false,
        renderInSidebar: true,
        settingsRoute: false,
        views: [
            {
                permission: Permissions.StockView,
                path: '/:prefix/priceMaintenance/view',
                name: 'View',
                mini: 'V',
                component: ViewProductPriceMaintenanceLayout,
                redirect: false,
                renderInSidebar: true,
                settingsRoute: false,
            },
            {
                permission: Permissions.StockManage,
                path: '/:prefix/priceMaintenance/update',
                name: 'Update',
                mini: 'U',
                component: UpdateProductPriceMaintenanceLayout,
                redirect: false,
                renderInSidebar: true,
                settingsRoute: false,
            },
        ],
    },
    {
        collapse: false,
        permission: Permissions.ActualCostView,
        path: '/:prefix/actual-cost-log',
        name: 'Actual Cost Log',
        icon: (): JSX.Element => <Icon size={30} icon={IconResources.Pending} />,
        component: ActualCostAdjustmentLog,
        redirect: false,
        renderInSidebar: true,
        settingsRoute: false,
    },
    {
        collapse: false,
        permission: Permissions.StockView,
        path: '/:prefix',
        pathTo: '/:prefix/priceMaintenance/view',
        name: 'Home Page',
        redirect: true,
        renderInSidebar: false,
        settingsRoute: false,
    },
];

const getAllRoutes = (route: RouteModel, calculatedRoutes: [string, RouteModel][] = []): [string, RouteModel][] => {
    calculatedRoutes.push([route.path, route]);
    route.views?.forEach((v) => getAllRoutes(v, calculatedRoutes));
    return calculatedRoutes;
};

export const routePathConfigLookup = new Map(MainRoutes.reduce<[string, RouteModel][]>((acc, r) => getAllRoutes(r, acc), []));

export default MainRoutes;
