import * as React from 'react';
import { observer, inject } from 'mobx-react';
import { RouteComponentProps } from 'react-router-dom';
import UpdateProductPriceMaintenanceStore from './UpdateProductPriceMaintenanceStore';
import UpdateProductPriceMassMaintenanceTableLayout from './UpdateProductPriceMaintenanceTable';
import { Card, CardHeader, CardBody, CardIcon, Icon, Snackbar, NotAuthorised, GridContainer, GridItem, Button } from '@vulcan/vulcan-materialui-theme';
import { IconResources } from 'src/Shared/Constants';
import ErrorPanel from './ErrorPanel';
import { SecurityStore } from 'src/App/Security';
import Permissions from 'src/App/Security/Permissions';
import CustomSweetAlert from 'src/Shared/Alert/CustomSweetAlert';
import { Tooltip, Zoom } from '@material-ui/core';

interface Props extends RouteComponentProps {
    updateProductPriceMaintenanceStore?: UpdateProductPriceMaintenanceStore;
    securityStore?: SecurityStore;
}

@inject('updateProductPriceMaintenanceStore')
@inject('securityStore')
@observer
export class UpdateProductPriceMaintenanceLayout extends React.Component<Props, {}> {
    private securityPolicy: Record<string, boolean>;

    constructor(props: Props) {
        super(props);
        this.securityPolicy = {
            canManageStock: this.props.securityStore!.hasPermission(Permissions.StockManage),
        };
    }

    public render(): React.ReactNode {
        if (!this.securityPolicy.canManageStock) {
            return <NotAuthorised />;
        }

        return (
            <div className='container'>
                <Card className='card'>
                    <CardHeader color='primaryColor' icon={true} className='card-header'>
                        <CardIcon className='card-icon' color='warning'>
                            <Icon icon={IconResources.Approve} />
                        </CardIcon>
                        <GridContainer justify='space-between' style={{ marginTop: '15px' }}>
                            <GridItem md={4} xs={12}>
                                <h4 className='card-title'>Update Prices</h4>
                            </GridItem>
                            <GridItem md={8} xs={12}>
                                <GridContainer justify='flex-end' alignItems='center'>
                                    <GridItem>
                                        <ErrorPanel {...this.props} />
                                    </GridItem>
                                    <GridItem>
                                        <input
                                            accept='.xls, .xlsx'
                                            style={{ display: 'none' }}
                                            id='raised-button-file'
                                            type='file'
                                            onChange={async ({ target }: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
                                                await this.props.updateProductPriceMaintenanceStore!.import(target.files!);
                                                target.value = ''; //erase value in case a file with the same name gets loaded
                                            }}
                                        />
                                        <label htmlFor='raised-button-file'>
                                            <Button component='span' type='button' color='warning'>
                                                Import
                                            </Button>
                                        </label>
                                    </GridItem>
                                    <GridItem>
                                        <Tooltip title={this.props.updateProductPriceMaintenanceStore!.tooltipText} TransitionComponent={Zoom}>
                                            <span>
                                                <Button
                                                    disabled={this.props.updateProductPriceMaintenanceStore!.isDisabledSubmitButton}
                                                    type='button'
                                                    color='warning'
                                                    onClick={this.props.updateProductPriceMaintenanceStore!.showPriceSubmitAlert}
                                                >
                                                    Submit
                                                </Button>
                                            </span>
                                        </Tooltip>
                                    </GridItem>
                                </GridContainer>
                            </GridItem>
                        </GridContainer>
                    </CardHeader>
                    <CardBody>
                        <UpdateProductPriceMassMaintenanceTableLayout {...this.props} />
                    </CardBody>
                </Card>

                <CustomSweetAlert
                    show={this.props.updateProductPriceMaintenanceStore!.isSubmitAlertOpen}
                    hideAlert={this.props.updateProductPriceMaintenanceStore!.hidePriceSubmitAlert}
                    title={`Submit prices?`}
                    message={'Prices for the imported products will be updated. This cannot be undone.'}
                    onSuccess={this.props.updateProductPriceMaintenanceStore!.submit}
                />

                <Snackbar
                    place='br'
                    color={this.props.updateProductPriceMaintenanceStore!.alertType}
                    icon={(): JSX.Element => <Icon icon={IconResources.AddAlert} />}
                    message={this.props.updateProductPriceMaintenanceStore!.alertMessage}
                    open={this.props.updateProductPriceMaintenanceStore!.alert}
                    closeNotification={(): void => this.props.updateProductPriceMaintenanceStore!.hideAlert()}
                    close={true}
                />
            </div>
        );
    }
}
export default UpdateProductPriceMaintenanceLayout;
