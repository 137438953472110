import ValidationResultType from './ValidationResultType';
import { IconResources } from 'src/Shared/Constants';

export class ValidationResult {
    public type: ValidationResultType = 3;
    public message = 'Success';
    public icon = IconResources.Checkmark;
    public color = 'success';

    constructor(fields?: Partial<ValidationResult>) {
        Object.assign(this, fields);
    }
}

export default ValidationResult;
