import { action, observable } from 'mobx';

export class ConfigureAvailabilityTableSettingsStore {
    @observable public showCustomerWarehouses = false;
    @observable public showWarehousesWithAvailableStock = false;

    @action public setShowCustomerWarehouses(value: boolean): void {
        this.showCustomerWarehouses = value;
    }

    @action public setShowWarehousesWithAvailableStock(value: boolean): void {
        this.showWarehousesWithAvailableStock = value;
    }
}
export default ConfigureAvailabilityTableSettingsStore;
