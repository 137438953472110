import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useDisplayMode, DisplayMode } from '../hooks/use-display-mode';

const useTableHeaderStyles = makeStyles({
    tableHeader: {
        fontWeight: 500,
        fontSize: 17,
        paddingTop: 10,
        paddingBottom: 10,
        display: 'grid',
        gridTemplateColumns: 'repeat(11, 1fr)',
    },
});

export const TableHeader = (prop: { headers: string[] }) => {
    const { displayMode } = useDisplayMode();
    const styles = useTableHeaderStyles();
    return displayMode === DisplayMode.ConstrainedLayout ? null : (
        <>
            <div className={styles.tableHeader}>
                {prop.headers.map((h) => (
                    <div key={h}>{h}</div>
                ))}
            </div>
        </>
    );
};
