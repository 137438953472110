import { BaseStore } from 'src/App/Infrastructure';
import ConfigureAvailabilityTableSettingsStore from './ConfigureAvailabilityTableSettingsStore';
import { observable, action, runInAction } from 'mobx';
import TableModel from './TableModel';
import * as _ from 'lodash';

export class ConfigureAvailabilityStore extends BaseStore {
    public settingStore: ConfigureAvailabilityTableSettingsStore;

    private tableDataCache: TableModel[] = [];
    @observable public tableData: TableModel[] = [];
    @observable public tableDataLoading = false;
    @observable public searchInputValue = '';

    constructor(settingStore: ConfigureAvailabilityTableSettingsStore) {
        super();
        this.settingStore = settingStore;
        this.tableDataCache = this.createFakeData();
        this.updateTableControl();
    }

    private createFakeData(): TableModel[] {
        const row = new TableModel({
            profitCentre: 'North Island',
            warehouse: 'Auckland',
            binNumber: 'Bin123',
            stockClass: 'Not classified',
            stockHoldingType: 'Non serialised',
            standardCost: 20,
            listPrice: 20,
            saleUnits: 'Tonne',
            purchaseUnits: 'Each',
        });

        const tableData: TableModel[] = [];
        for (let j = 0; j < 20; j++) {
            const newRow = _.cloneDeep(row);
            newRow.defaultSerialCode += '+' + j;
            tableData.push(newRow);
        }

        return tableData;
    }

    @action private updateTableControl(): void {
        this.tableDataLoading = true;
        const filterTableData = this.tableDataCache;

        runInAction(() => {
            this.tableData = filterTableData;
            this.tableDataLoading = false;
        });
    }

    @action public clearSearchResults(): void {
        this.searchInputValue = '';
        this.updateTableControl();
    }

    @action public searchTable(searchTerm: string): void {
        this.searchInputValue = searchTerm;
        this.updateTableControl();
    }
}
export default ConfigureAvailabilityStore;
