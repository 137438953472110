import * as React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import { inject, observer } from 'mobx-react';
import { observable, action } from 'mobx';
import { MenuList } from '@material-ui/core';
import * as _ from 'lodash';
import { RouteComponentProps, generatePath } from 'react-router';
import UserSettingModel from '../Security/UserSettingModel';
import { SecurityStore } from '../Security';

interface MatchParams {
    prefix: string;
}

interface Props extends RouteComponentProps<MatchParams> {
    securityStore?: SecurityStore;
    handleClose?: Function;
    className?: string;
}

@inject('securityStore')
@observer
export class TenantsSelect extends React.Component<Props, {}> {
    @observable private selectedTenant: UserSettingModel | undefined;

    constructor(props: Props) {
        super(props);
        this.selectedTenant = this.props.securityStore!.selectedTenant;
    }

    public render(): JSX.Element {
        return <MenuList role='menu'>{this.renderMenuItems(this.props.securityStore!.userTenants)}</MenuList>;
    }

    private renderMenuItems(tenants: UserSettingModel[]): JSX.Element[] {
        return tenants.map((x: UserSettingModel) => (
            <MenuItem
                button={true}
                data-id={x.tenantName}
                onClick={this.handleMenuItemClick}
                className={this.props.className || ''}
                key={x.tenantName}
                value={x.tenantName}
                selected={this.selectedTenant && this.selectedTenant.tenantName.toLowerCase() === x.tenantName.toLowerCase()}
            >
                {x.friendlyName}
            </MenuItem>
        ));
    }

    @action private handleMenuItemClick = (e: React.MouseEvent<HTMLElement>): void => {
        const tenant = this.props.securityStore!.userTenants.find((x) => x.tenantName.toLowerCase() === e.currentTarget.dataset.id!.toLowerCase());
        this.selectedTenant = _.cloneDeep(tenant);
        this.props.securityStore!.setSelectedTenant(this.selectedTenant!.tenantName);
        const prefix = this.props.securityStore!.getTenantPrefixFromCookieTenantName;
        const newPath = generatePath(this.props.match.path, { prefix });
        const newUrl = this.props.location.pathname.replace(this.props.match.url, newPath) + this.props.location.search;
        window.location.replace(newUrl);
        if (this.props.handleClose) {
            this.props.handleClose();
        }
    };
}

export default TenantsSelect;
