import React from 'react';
import AppStore from './AppStore';
import { observer, Provider } from 'mobx-react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import SiteWrapper from './Layout/SiteWrapper';
import { ErrorBoundary, vulcanMuiTheme } from '@vulcan/vulcan-materialui-theme';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core';
import { AuthContext, AuthContextProvider } from '../ActualCostAdjustmentLog/utils/app-context/auth-context';
import { StylesProvider, createGenerateClassName } from '@material-ui/core/styles';
import { NotificationProvider } from 'src/Shared/Notification/notification-context';

const generateClassName = createGenerateClassName({
    productionPrefix: 'i',
});

@observer
export class App extends React.Component<{}, {}> {
    private appStore: AppStore;
    constructor(props: {}) {
        super(props);

        this.appStore = new AppStore();
    }

    public componentDidMount = async (): Promise<void> => {
        await this.appStore.loadAppData();
    };

    public render(): React.ReactNode {
        if (!this.appStore.isSettingLoaded || !this.appStore.authStore.isLoggedIn) {
            return null;
        }

        const authContext: AuthContext = { getAuthToken: this.appStore.getStores().authStore.getToken };
        return (
            <MuiThemeProvider theme={createMuiTheme(vulcanMuiTheme)}>
                <StylesProvider generateClassName={generateClassName}>
                    <NotificationProvider>
                        <ErrorBoundary logError={this.appStore.log}>
                            <Provider {...this.appStore.getStores()}>
                                <AuthContextProvider contextState={authContext}>
                                    <Router>
                                        <Switch>
                                            <Redirect from='/' to={'/index'} exact={true} />
                                            <Route path='/:prefix' render={(): JSX.Element => <SiteWrapper appStore={this.appStore} />} />
                                        </Switch>
                                    </Router>
                                </AuthContextProvider>
                            </Provider>
                        </ErrorBoundary>
                    </NotificationProvider>
                </StylesProvider>
            </MuiThemeProvider>
        );
    }
}

export default App;
