import { round } from 'lodash';

export function toCurrency(value: number): string {
    return !Number(value) || value === 0 ? '-' : '$' + new Intl.NumberFormat(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value);
}

export function toKilograms(value: number): string {
    return new Intl.NumberFormat(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(value) + ' KG';
}

export function localeCompare(a: string, b: string): number {
    return a.localeCompare(b, undefined, { ignorePunctuation: true, sensitivity: 'base' });
}

export function toFixedNumber(value: number | undefined, precision: number): number {
    return value ? round(value, precision) : 0;
}

export function toFixedNumberString(value: string | undefined, precision: number): string {
    return value ? toFixedNumber(parseFloat(value), precision).toString() : '';
}
