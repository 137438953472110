import * as React from 'react';
import { Card, CardHeader, CardBody, GridContainer, GridItem } from '@vulcan/vulcan-materialui-theme';
import ConfigureAvailabilityTableSettings from './ConfigureAvailabilityTableSettings';
import ConfigureAvailabilityTableSearch from './ConfigureAvailabilityTableSearch';
import ConfigureAvailabilityTable from './ConfigureAvailabilityTable';
import { RouteComponentProps } from 'react-router-dom';
import ConfigureAvailabilityStore from './ConfigureAvailabilityStore';
import { inject, observer } from 'mobx-react';

interface Props extends RouteComponentProps {
    configureAvailabilityStore?: ConfigureAvailabilityStore;
}

@inject('configureAvailabilityStore')
@observer
export class ConfigureAvailabilityLayout extends React.Component<Props> {
    public render(): React.ReactNode {
        return (
            <div className='container'>
                <Card className='main-card'>
                    <CardHeader color='success' icon={true}>
                        <h3 className='card-title'>configure product availability</h3>
                    </CardHeader>
                    <CardBody>
                        <GridContainer>
                            <GridItem xl={4} lg={4} md={12} sm={12} xs={12}>
                                <ConfigureAvailabilityTableSearch />
                            </GridItem>
                            <GridItem xl={8} lg={8} md={12} sm={12} xs={12}>
                                <ConfigureAvailabilityTableSettings />
                            </GridItem>
                        </GridContainer>
                        <GridContainer>
                            <GridItem xl={12} lg={12} md={12} sm={12} xs={12}>
                                <ConfigureAvailabilityTable />
                            </GridItem>
                        </GridContainer>
                    </CardBody>
                </Card>
            </div>
        );
    }
}
export default ConfigureAvailabilityLayout;
