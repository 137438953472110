import { useAuthContext } from '../app-context/auth-context';
import { createHttpClient } from '../http-helpers';
import { fetchWarehouseList } from './fetch-warehouses';
import { fetchProductList } from './fetch-products';
import { useAppConfig } from '../app-context/app-config-context';
import { searchActualCostLog, ActualCostLogSearch } from './actual-cost-log-search';
import { useMemo } from 'react';

export const useInventoryApi = () => {
    const { getAuthToken } = useAuthContext();
    const {
        apiUrls: { inventoryApi },
    } = useAppConfig();
    const httpClient = useMemo(() => createHttpClient(getAuthToken), [getAuthToken]);

    const api = useMemo(
        () => ({
            fetchProductList: () => fetchProductList(httpClient, inventoryApi),
            fetchWarehouseList: () => fetchWarehouseList(httpClient, inventoryApi),
            searchActualCostLog: (searchRequest: ActualCostLogSearch) => searchActualCostLog(httpClient, inventoryApi, searchRequest),
        }),
        [inventoryApi, httpClient]
    );

    return api;
};
