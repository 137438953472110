import React from 'react';
import { ActualCostLogEntry } from 'src/ActualCostAdjustmentLog/utils/api/actual-cost-log-search';
import { toCurrency } from 'src/Shared/Utils';
import moment from 'moment';
import { makeStyles } from '@material-ui/core';
import { useAppConfig } from 'src/ActualCostAdjustmentLog/utils/app-context/app-config-context';
import { useDisplayMode, DisplayMode } from '../hooks/use-display-mode';

export const ActualCostLogRows = (props: { items: ActualCostLogEntry[] }) => {
    return (
        <>
            {props.items?.map((item, index) => (
                <ActualCostLogRow item={item} itemIndex={index} key={item.id} />
            ))}
        </>
    );
};

const ActualCostLogRow = ({ itemIndex, item }: ActualCostLogRowProps) => {
    const { displayDateFormat } = useAppConfig();
    const { displayMode } = useDisplayMode();
    const originalStockValue = isUndefined(item.originalInStock) || isUndefined(item.originalEachCost) ? undefined : item.originalInStock! * item.originalEachCost!;
    const totalAmount = isUndefined(item.originalInStock) || isUndefined(item.originalEachCost) ? undefined : item.eachCost * (item.originalInStock! + item.inStockAdjustment!);
    const originalValue = isUndefined(item.originalInStock) || isUndefined(item.originalEachCost) ? undefined : item.originalInStock! * item.originalEachCost!;
    const stockAdjustmentValue = isUndefined(item.inStockAdjustment) ? undefined : totalAmount! - originalValue!;
    
    const displayItem: ActualCostLowRowFields = {
        date: item.dateTimeInUtc && moment(item.dateTimeInUtc).format(displayDateFormat),
        warehouseCode: item.warehouseCode,
        reference: item.reference,
        originalInStock: item.originalInStock ? `${item.originalInStock}` : '-',
        inStockAdjustment: item.inStockAdjustment ? `${item.inStockAdjustment}` : '-',
        originalStockValue: isUndefined(originalStockValue) ? '-' : toCurrency(originalStockValue!),
        stockAdjustmentValue: stockAdjustmentValue ? toCurrency(stockAdjustmentValue) : '-',
        totalStock: isUndefined(item.originalInStock) || isUndefined(item.inStockAdjustment) ? '-' : `${item.originalInStock! + item.inStockAdjustment!}`,
        totalValue: totalAmount ? toCurrency(totalAmount) : '-',
        eachCost: toCurrency(item.eachCost),
        tonCost: toCurrency(item.tonCost),
        rowIndex: itemIndex,
    };
    return displayMode === DisplayMode.FullView ? <ActualCostLogRowForFullLayout {...displayItem} /> : <ActualCostLogRowConstrainedLayout {...displayItem} />;
};

const ActualCostLogRowForFullLayout = (item: ActualCostLowRowFields) => {
    const classes = useActualCostLogStyleForFullLayout();
    return (
        <div className={item.rowIndex % 2 === 0 ? `${classes.evenRow} ${classes.row}` : classes.row}>
            <div>{item.date}</div>
            <div>{item.warehouseCode}</div>
            <div>{item.reference}</div>
            <div className={classes.alignRight}>{item.originalInStock}</div>
            <div className={classes.alignRight}>{item.inStockAdjustment}</div>
            <div className={classes.alignRight}>{item.originalStockValue}</div>
            <div className={classes.alignRight}>{item.stockAdjustmentValue}</div>
            <div className={classes.alignRight}>{item.totalStock}</div>
            <div className={classes.alignRight}>{item.totalValue}</div>
            <div className={classes.alignRight}>{item.eachCost}</div>
            <div className={classes.alignRight}>{item.tonCost}</div>
        </div>
    );
};

const ActualCostLogRowConstrainedLayout = (props: ActualCostLowRowFields) => {
    const classes = useActualCostLogStyleForConstrainedLayout();
    return (
        <div className={props.rowIndex % 2 === 0 ? `${classes.evenRow} ${classes.row}` : classes.row}>
            <div className={classes.inlineValue}>
                <div>Date:</div>
                <div>{props.date}</div>
            </div>
            <div className={classes.inlineValue}>
                <div>Warehouse:</div>
                <div>{props.warehouseCode}</div>
            </div>
            <div className={classes.inlineValue}>
                <div>Original Stock:</div>
                <div>{props.originalInStock}</div>
            </div>
            <div className={classes.inlineValue}>
                <div>Stock Adjusment</div>
                <div>{props.inStockAdjustment}</div>
            </div>
            <div className={classes.inlineValue}>
                <div>Total Stock</div>
                <div>{props.totalStock}</div>
            </div>
            <div className={classes.inlineValue}>
                <div>Cost (Each/Ton):</div>
                <div>{props.eachCost} / ${props.tonCost}</div>
            </div>
            <div className={classes.inlineValue}>
                <div>Value Adjustment</div>
                <div>{props.stockAdjustmentValue}</div>
            </div>
            <div className={classes.inlineValue}>
                <div>Total Stock</div>
                <div>{props.totalStock}</div>
            </div>

            <div className={classes.inlineValue}>
                <div>Total Value</div>
                <div>{props.totalValue}</div>
            </div>

            <div className={classes.inlineValue}>
                <div>Reference:</div>
                <div>{props.reference}</div>
            </div>
        </div>
    );
};

const isUndefined = (item: any) => item === undefined || item === null;

const useActualCostLogStyleForConstrainedLayout = makeStyles({
    row: {
        display: 'grid',
        rowGap: '8px',
        columnGap: 16,
        gridTemplateColumns: 'repeat(auto-fit,minmax(200px,1fr))',
        alignItems: 'center',
        padding: 8,
    },
    inlineValue: {
        display: 'flex',
        '&>div:first-of-type': {
            fontWeight: 500,
            fontSize: 17,
            marginRight: 8,
        },
    },
    evenRow: {
        background: '#00000008',
    },
});

const useActualCostLogStyleForFullLayout = makeStyles({
    row: {
        display: 'grid',
        gridTemplateColumns: 'repeat(11, 1fr)',
        paddingTop: 7,
        paddingLeft: 5,
        paddingBottom: 7,
        '&:hover': {
            background: '#0000000d',
        },
    },
    evenRow: {
        background: '#00000008',
    },
    alignRight:{
        justifySelf: 'end',
    }
});

interface ActualCostLogRowProps {
    itemIndex: number;
    item: ActualCostLogEntry;
}

interface ActualCostLowRowFields {
    date: string;
    warehouseCode: string;
    reference: string;
    originalInStock: string;
    inStockAdjustment: string;
    originalStockValue: string;
    stockAdjustmentValue: string;
    totalStock: string;
    totalValue: string;
    eachCost: string;
    tonCost: string;
    rowIndex: number;
}
