export class ViewProductPriceMaintenanceTableDataRowModel {
    public productCode = '';
    public productCategory = '';
    public productGroup = '';
    public warehouseCode = '';
    public stockClass = '';
    public stockClassColour = '';
    public availableStock = 0;
    public eachWeight = 0;
    public eachWAC = 0;
    public eachLLC = 0;
    public eachStandardCost = 0;
    public eachListPrice = 0;

    constructor(fields?: Partial<ViewProductPriceMaintenanceTableDataRowModel>) {
        Object.assign(this, fields);
    }
}

export default ViewProductPriceMaintenanceTableDataRowModel;
