import React from 'react';
import { inject, observer } from 'mobx-react';
import { StringResources } from 'src/Shared/Constants';
import ReactTable, { CellInfo, Column, ReactTableDefaults, SortingRule } from 'react-table';
import ViewProductPriceMaintenanceStore from './ViewProductPriceMaintenanceStore';
import { ViewProductPriceMaintenanceTableDataRowModel } from './Models/ViewProductPriceMaintenanceTableDataRowModel';
import { ExportableTableColumn } from '@vulcan/vulcan-materialui-theme/build/utils';
import { toCurrency, toKilograms } from 'src/Shared/Utils';

interface ViewProductPriceMaintenanceTableProps {
    viewProductPriceMaintenanceStore?: ViewProductPriceMaintenanceStore;
}

@inject('viewProductPriceMaintenanceStore')
@observer
export class ViewProductPriceMaintenanceTable extends React.Component<ViewProductPriceMaintenanceTableProps> {
    private handlePageChange = (page: number): void => {
        this.props.viewProductPriceMaintenanceStore!.setPage(page);
    };

    private handleSortingChange = (newSorted: SortingRule[]): void => {
        this.props.viewProductPriceMaintenanceStore!.setSorting(newSorted);
        this.props.viewProductPriceMaintenanceStore!.setPage(0);
    };

    public render(): JSX.Element {
        return (
            <ReactTable
                data={this.props.viewProductPriceMaintenanceStore!.tableData.slice()}
                columns={this.styledTableColumns}
                column={{
                    ...ReactTableDefaults.column,
                    style: { whiteSpace: 'unset' },
                }}
                loading={this.props.viewProductPriceMaintenanceStore!.tableDataLoading}
                noDataText={this.props.viewProductPriceMaintenanceStore!.tableDataLoading ? '' : StringResources.TableNoRowsFound}
                minRows={this.props.viewProductPriceMaintenanceStore!.tableData.length > 0 ? 0 : 16}
                pageSize={14}
                page={this.props.viewProductPriceMaintenanceStore!.pageNumber}
                onPageChange={this.handlePageChange}
                onSortedChange={this.handleSortingChange}
                className='react-table -striped -highlight'
                filterable={false}
                sortable={true}
                sorted={this.props.viewProductPriceMaintenanceStore!.sorting}
                showPageJump={false}
                showPageSizeOptions={false}
                showPaginationTop={false}
                showPaginationBottom={true}
                showPagination={true}
                collapseOnDataChange={false}
                collapseOnSortingChange={false}
                collapseOnPageChange={false}
                resizable={false}
            />
        );
    }

    private get styledTableColumns(): Column<ViewProductPriceMaintenanceTableDataRowModel>[] {
        const columns = ViewProductPriceMaintenanceTable.exportableTableColumns.map((exportableTableColumn) => exportableTableColumn.column);
        const classColumn = columns.find((column) => column.id === 'class');
        if (classColumn) {
            classColumn.Cell = (cell: CellInfo): JSX.Element => (
                <div className='product-class-cell' style={{ backgroundColor: cell.original.stockClassColour }}>
                    {cell.value}
                </div>
            );
        }
        return columns;
    }

    public static get exportableTableColumns(): ExportableTableColumn<ViewProductPriceMaintenanceTableDataRowModel>[] {
        const exportColumnDefault = (value: string | number | object): string | number | Date | object => value;
        const exportTypeGeneral = '';
        const exportTypeCurrency = '"$"#,##0.00';

        return [
            {
                column: {
                    id: 'product-code',
                    Header: 'Product Code',
                    accessor: 'productCode',
                    width: 180,
                },
                exportColumn: exportColumnDefault,
                exportType: exportTypeGeneral,
            },
            {
                column: {
                    id: 'product-category',
                    Header: 'Category',
                    accessor: 'productCategory',
                },
                exportColumn: exportColumnDefault,
                exportType: exportTypeGeneral,
            },
            {
                column: {
                    id: 'product-group',
                    Header: 'Group',
                    accessor: 'productGroup',
                },
                exportColumn: exportColumnDefault,
                exportType: exportTypeGeneral,
            },
            {
                column: {
                    id: 'warehouse-code',
                    Header: 'Warehouse',
                    accessor: 'warehouseCode',
                },
                exportColumn: exportColumnDefault,
                exportType: exportTypeGeneral,
            },
            {
                column: {
                    id: 'class',
                    Header: 'Class',
                    accessor: 'stockClass',
                },
                exportColumn: exportColumnDefault,
                exportType: exportTypeGeneral,
            },
            {
                column: {
                    id: 'available-stock',
                    Header: 'Available',
                    accessor: 'availableStock',
                    Cell: (cell: CellInfo): string => cell.value.toFixed(2),
                },
                exportColumn: exportColumnDefault,
                exportType: exportTypeGeneral,
            },
            {
                column: {
                    id: 'each-weight',
                    Header: 'Weight',
                    accessor: 'eachWeight',
                    Cell: (cell: CellInfo): string => toKilograms(cell.value),
                },
                exportColumn: exportColumnDefault,
                exportType: exportTypeGeneral,
            },
            {
                column: {
                    id: 'each-wac',
                    Header: 'WAC (Each)',
                    accessor: 'eachWAC',
                    width: 130,
                    Cell: (cell: CellInfo): string => toCurrency(cell.value),
                },
                exportColumn: exportColumnDefault,
                exportType: exportTypeCurrency,
            },
            {
                column: {
                    id: 'each-llc',
                    Header: 'LLC (Each)',
                    accessor: 'eachLLC',
                    width: 120,
                    Cell: (cell: CellInfo): string => toCurrency(cell.value),
                },
                exportColumn: exportColumnDefault,
                exportType: exportTypeCurrency,
            },
            {
                column: {
                    id: 'each-standard-cost',
                    Header: 'Std Cost (Each)',
                    accessor: 'eachStandardCost',
                    width: 150,
                    Cell: (cell: CellInfo): string => toCurrency(cell.value),
                },
                exportColumn: exportColumnDefault,
                exportType: exportTypeCurrency,
            },
            {
                column: {
                    id: 'each-list-price',
                    Header: 'List Price (Each)',
                    accessor: 'eachListPrice',
                    width: 150,
                    Cell: (cell: CellInfo): string => toCurrency(cell.value),
                },
                exportColumn: exportColumnDefault,
                exportType: exportTypeCurrency,
            },
        ];
    }
}
export default ViewProductPriceMaintenanceTable;
