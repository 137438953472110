import * as React from 'react';
import ReactTable, { CellInfo, Column } from 'react-table';
import { Switch } from '@material-ui/core';
import TableModel from './TableModel';
import { Button, Icon } from '@vulcan/vulcan-materialui-theme';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { IconResources } from 'src/Shared/Constants';
import ConfigureAvailabilityStore from './ConfigureAvailabilityStore';
import { inject, observer } from 'mobx-react';
import { toCurrency } from 'src/Shared/Utils';

const styles = {
    colorSwitchBase: {
        color: '#fafafa',
        '&$colorChecked': {
            color: '#00acc1',
            '& + $colorBar': {
                backgroundColor: '#00acc1',
            },
        },
    },
    colorBar: {},
    colorChecked: {},
    cssRoot: {
        margin: '5px',
        padding: '8px',
        fontSize: '14px',
        backgroundColor: '#00acc1',
        '&:hover': {
            backgroundColor: '#00acc1',
        },
        '&:active': {
            backgroundColor: '#00acc1',
        },
        '&:focus': {
            backgroundColor: '#00acc1',
        },
    },
};

interface Props {
    // tslint:disable-next-line: no-any
    classes?: any;
    configureAvailabilityStore?: ConfigureAvailabilityStore;
}

@inject('configureAvailabilityStore')
@observer
export class ConfigureAvailabilityTable extends React.Component<Props, {}> {
    public render(): React.ReactNode {
        const store = this.props.configureAvailabilityStore!;
        const { classes } = this.props;
        const data = [...store.tableData];

        return (
            <>
                <div className='table-title-row'>
                    <h3>Configure warehouse that stock the product:</h3>
                    <div>
                        <Button variant='contained' color='primary' className={classNames(classes.cssRoot)}>
                            <Icon size={30} icon={IconResources.Redo} />
                        </Button>
                        <Button variant='contained' color='primary' className={classNames(classes.cssRoot)}>
                            <Icon size={30} icon={IconResources.Undo} />
                        </Button>
                        <Button variant='contained' color='primary' className={classNames(classes.cssRoot)}>
                            SET DEFAULTS
                        </Button>
                    </div>
                </div>

                <ReactTable
                    data={data}
                    columns={this.tableColumns}
                    loading={false}
                    minRows={9}
                    pageSize={9}
                    className='-striped -highlight'
                    filterable={false}
                    sortable={false}
                    defaultSorted={[{ id: 'profit-centre', desc: false }]}
                    showPageJump={false}
                    showPageSizeOptions={false}
                    showPaginationTop={false}
                    showPaginationBottom={false}
                    showPagination={false}
                    collapseOnDataChange={false}
                    collapseOnSortingChange={false}
                    collapseOnPageChange={false}
                    resizable={false}
                />
            </>
        );
    }

    private handleTableCheckBoxChanged(row: TableModel): void {
        row.isChecked = !row.isChecked;
        // ended up using forceUpdate because control various states and updates constantly in each cell,
        // we rendered each cell as a separate component which is controller via controller.
        this.forceUpdate();
    }

    private renderEditable = (cellInfo: CellInfo): JSX.Element => {
        return (
            <div className='edit-cell'>
                {cellInfo.value}
                <Icon size={15} icon={IconResources.Pencil} />
            </div>
        );
    };

    public get tableColumns(): Column<TableModel>[] {
        return [
            {
                id: 'check-box',
                Header: 'Availabile',
                accessor: 'isChecked',
                width: 80,
                Cell: (cell: CellInfo): JSX.Element => {
                    return (
                        <Switch
                            key={cell.index}
                            classes={{ switchBase: this.props.classes.colorSwitchBase, checked: this.props.classes.colorChecked, track: this.props.classes.colorBar }}
                            checked={cell.value}
                            onChange={(): void => {
                                this.handleTableCheckBoxChanged(cell.original);
                            }}
                        />
                    );
                },
            },
            {
                id: 'profit-centre',
                Header: 'Profit centre',
                accessor: 'profitCentre',
                width: 120,
            },
            {
                id: 'warehouse',
                Header: 'Warehouse',
                accessor: 'warehouse',
                width: 110,
            },
            {
                id: 'bin-number',
                Header: 'Bin number',
                accessor: 'binNumber',
                Cell: this.renderEditable,
                width: 110,
            },
            {
                id: 'stock-class',
                Header: 'Stock class',
                accessor: 'stockClass',
                width: 100,
            },
            {
                id: 'stock-holding-type',
                Header: 'Stock holding type',
                accessor: 'stockHoldingType',
                width: 180,
            },
            {
                id: 'standard-cost',
                Header: 'Standard cost',
                accessor: 'standardCost',
                Cell: (cell: CellInfo): JSX.Element => {
                    return (
                        <div className='edit-cell'>
                            {toCurrency(cell.value)}
                            <Icon size={15} icon={IconResources.Pencil} />
                        </div>
                    );
                },
                width: 140,
            },
            {
                id: 'list-price',
                Header: 'List price',
                accessor: 'listPrice',
                Cell: (cell: CellInfo): JSX.Element => {
                    return (
                        <div className='edit-cell'>
                            {toCurrency(cell.value)}
                            <Icon size={15} icon={IconResources.Pencil} />
                        </div>
                    );
                },
                width: 100,
            },
            {
                id: 'sale-units',
                Header: 'Sale units',
                accessor: 'saleUnits',
                width: 100,
            },
            {
                id: 'purchase-units',
                Header: 'Purchase units',
                accessor: 'purchaseUnits',
                width: 140,
            },
        ];
    }
}

export default withStyles(styles)(ConfigureAvailabilityTable);
