import * as React from 'react';
import { Switch } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { GridContainer, GridItem } from '@vulcan/vulcan-materialui-theme';
import { inject, observer } from 'mobx-react';
import ConfigureAvailabilityTableSettingsStore from './ConfigureAvailabilityTableSettingsStore';

const styles = {
    colorSwitchBase: {
        color: '#fafafa',
        '&$colorChecked': {
            color: '#00acc1',
            '& + $colorBar': {
                backgroundColor: '#00acc1',
            },
        },
    },
    colorBar: {},
    colorChecked: {},
};

interface Props {
    // tslint:disable-next-line: no-any
    classes?: any;
    configureAvailabilityTableSettingsStore?: ConfigureAvailabilityTableSettingsStore;
}

@inject('configureAvailabilityTableSettingsStore')
@observer
export class ConfigureAvailabilityTableSettings extends React.Component<Props, {}> {
    public render(): React.ReactNode {
        const { classes } = this.props;
        return (
            <fieldset className='fieldset'>
                <legend className='label'>Table Settings</legend>
                <GridContainer className='row'>
                    <GridItem xl={9} lg={9} md={9} sm={9} xs={9}>
                        <label>Show customer warehouses</label>
                    </GridItem>
                    <GridItem xl={3} lg={3} md={3} sm={3} xs={3}>
                        <Switch
                            className='toggle'
                            classes={{ switchBase: classes.colorSwitchBase, checked: classes.colorChecked, track: classes.colorBar }}
                            checked={this.props.configureAvailabilityTableSettingsStore!.showCustomerWarehouses}
                            onChange={this.handleShowCustomerWarehousesClick}
                        />
                    </GridItem>
                </GridContainer>
                <GridContainer className='row'>
                    <GridItem xl={9} lg={9} md={9} sm={9} xs={9}>
                        <label>Show only warehouses with available stock</label>
                    </GridItem>
                    <GridItem xl={3} lg={3} md={3} sm={3} xs={3}>
                        <Switch
                            className='toggle'
                            classes={{ switchBase: classes.colorSwitchBase, checked: classes.colorChecked, track: classes.colorBar }}
                            checked={this.props.configureAvailabilityTableSettingsStore!.showWarehousesWithAvailableStock}
                            onChange={this.handleShowWarehousesWithAvailableStockClick}
                        />
                    </GridItem>
                </GridContainer>
            </fieldset>
        );
    }

    private handleShowCustomerWarehousesClick = (event: React.ChangeEvent<HTMLInputElement>, _checked: boolean): void => {
        if (this.props.configureAvailabilityTableSettingsStore) {
            this.props.configureAvailabilityTableSettingsStore!.setShowCustomerWarehouses(event.target.checked);
        }
    };

    private handleShowWarehousesWithAvailableStockClick = (event: React.ChangeEvent<HTMLInputElement>, _checked: boolean): void => {
        if (this.props.configureAvailabilityTableSettingsStore) {
            this.props.configureAvailabilityTableSettingsStore!.setShowWarehousesWithAvailableStock(event.target.checked);
        }
    };
}

export default withStyles(styles)(ConfigureAvailabilityTableSettings);
