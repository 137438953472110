export class UpdateProductPriceMaintenanceImportDataRowModel {
    public productCode = '';
    public warehouseCode = '';
    public newStandardCost = '';
    public newListPrice = '';

    constructor(fields?: Partial<UpdateProductPriceMaintenanceImportDataRowModel>) {
        Object.assign(this, fields);
    }
}

export default UpdateProductPriceMaintenanceImportDataRowModel;
