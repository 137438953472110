import ValidationResult from './ValidationResult';

export class UpdateProductPriceMaintenanceTableDataRowModel {
    public productCode = '';
    public productGroup = '';
    public warehouseCode = '';
    public stockClass = '';
    public stockClassColour = '';
    public newStandardCost = 0;
    public newListPrice = 0;
    public validationResult: ValidationResult = new ValidationResult();

    constructor(fields?: Partial<UpdateProductPriceMaintenanceTableDataRowModel>) {
        Object.assign(this, fields);
    }
}

export default UpdateProductPriceMaintenanceTableDataRowModel;
