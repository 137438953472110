import React, { useCallback, useRef, useState } from 'react';
import { FormLabel } from '@material-ui/core';
import { OptionModel } from '@vulcan/vulcan-materialui-theme/build/components';
import AsyncSelect from 'react-select/async';
import { components } from 'react-select';
import SearchIcon from '@material-ui/icons/Search';
import Fuse from 'fuse.js';

export interface DropDownProps<T> {
    className: string;
    formLabel: string;
    searchPromptText: string;
    noSearchResultFoundText: string;
    placeholderText: string;
    limitTags?: number;
    allowMultiple?: boolean;
    loadOptions: (search: string) => Promise<T[]>;
    fieldsToSearch: string[];
    mapToOptionModel: (item: T) => OptionModel;
    onSelectionChanged: (selectedItems: string[]) => void;
}

const DropdownIndicator = (props) => {
    return (
        <components.DropdownIndicator {...props}>
            <SearchIcon />
        </components.DropdownIndicator>
    );
};

const ValueContainer = ({ children, selectProps, ...props }): React.ReactNode => {
    const limitTags = selectProps.limitTags;
    const valueLength = selectProps.value && selectProps.value.length;
    const shouldBadgeShow = valueLength > limitTags;
    return (
        <components.ValueContainer {...props}>
            {shouldBadgeShow && `${valueLength} items selected`}
            {children}
        </components.ValueContainer>
    );
};

const MultiValueContainer = ({ children, selectProps, ...props }): React.ReactNode => {
    const limitTags = selectProps.limitTags;
    const valueLength = selectProps.value.length;
    const shouldBadgeShow = valueLength > limitTags;
    return shouldBadgeShow ? null : <components.MultiValueContainer {...props}>{children}</components.MultiValueContainer>;
};

export const DropDown = <T,>({
    className,
    onSelectionChanged,
    placeholderText,
    searchPromptText,
    noSearchResultFoundText,
    formLabel,
    loadOptions,
    fieldsToSearch,
    mapToOptionModel,
    allowMultiple,
    limitTags,
}: DropDownProps<T>) => {
    const [selectedItems, setSelectedItems] = useState(new Set<string>());
    const [searchInput, onSearchInputChage] = useState<string>();
    const ref = useRef<Promise<Fuse<T>>>();
    const noOptionsMessage = useCallback(
        (props) => {
            const displayText = searchInput ? noSearchResultFoundText : searchPromptText;
            return <components.NoOptionsMessage {...props}>{displayText}</components.NoOptionsMessage>;
        },
        [searchInput, searchPromptText, noSearchResultFoundText]
    );
    const loadSelectOptions = useCallback(
        async (searchString: string) => {
            if (!ref.current) {
                ref.current = loadOptions(searchString).then(
                    (options) => new Fuse<T>(options, { keys: fieldsToSearch })
                );
            }
            const fuzzySearch = await ref.current!;
            const results = fuzzySearch.search(searchString) ?? [];
            return results.reduce<OptionModel[]>((acc, i) => {
                if (acc.length > 20) return acc;
                const optionModel = mapToOptionModel(i.item);
                if (selectedItems.has(optionModel.value)) return acc;
                acc.push(optionModel);
                return acc;
            }, []);
        },
        [loadOptions, fieldsToSearch, mapToOptionModel, selectedItems]
    );

    const onSelectedItemChange = useCallback(
        (options: OptionModel | OptionModel[]) => {
            const selectedItems = ([] as OptionModel[]).concat(options ?? []);
            const selectedValues = selectedItems.map((o) => o.value) ?? [];
            setSelectedItems(new Set(selectedValues));
            onSelectionChanged(selectedValues);
        },
        [onSelectionChanged, setSelectedItems]
    );

    return (
        <div className={className}>
            <FormLabel>{formLabel}</FormLabel>
            <AsyncSelect
                isMulti={allowMultiple}
                isClearable
                onInputChange={onSearchInputChage}
                defaultOptions
                placeholder={placeholderText}
                onChange={onSelectedItemChange}
                limitTags={limitTags ?? 3}
                loadOptions={loadSelectOptions}
                components={{ ValueContainer, MultiValueContainer, NoOptionsMessage: noOptionsMessage, DropdownIndicator }}
            ></AsyncSelect>
        </div>
    );
};
