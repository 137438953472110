interface HttpResponse<T> extends Response {
    parsedBody?: T;
}

export const createHttpClient = (getAuthToken: () => Promise<string>) => {
    return {
        get: <T>(url: string): Promise<HttpResponse<T>> => http<T>(url, getAuthToken),
        post: <T>(url: string, body: any): Promise<HttpResponse<T>> =>
            http<T>(
                new Request(url, {
                    method: 'post',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(body),
                }),
                getAuthToken
            ),
    };
};

export type HttpClient = ReturnType<typeof createHttpClient>;

async function http<T>(request: RequestInfo, getAuthToken: () => Promise<string>): Promise<HttpResponse<T>> {
    const authToken = await getAuthToken();
    const extendedRequestInfo = getRequestInfo(authToken, request);
    const [response] = await Promise.all([fetch(extendedRequestInfo), delay(500)]);
    try {
        // may error if there is no body
        (response as HttpResponse<T>).parsedBody = await response.json();
    } catch (ex) {}
    if (!response.ok) {
        throw new Error(response.statusText);
    }
    return response;
}

const delay = (timeoutInMillisecond: number) =>
    new Promise<boolean>((res) => {
        setTimeout(() => res(true), timeoutInMillisecond);
    });

const getRequestInfo = (authToken: string, request: RequestInfo): RequestInfo => {
    const defaultHeaders = { authorization: `Bearer ${authToken}` };
    if (typeof request === 'string') {
        return new Request(request, { headers: defaultHeaders });
    }
    var updatedRequest = request.clone();
    updatedRequest.headers.set('authorization', `Bearer ${authToken}`);
    return updatedRequest;
};
