import * as React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { Location } from 'history';
import { RouteModel } from '../Routes';
import { NotFound } from '@vulcan/vulcan-materialui-theme';

// tslint:disable-next-line: no-any
export const RenderRoute = (r: RouteModel): any => {
    if (r.redirect) {
        return <Redirect key={r.name} from={r.path} to={r.pathTo || '/'} exact={true} />;
    }

    if (r.views && r.views.length > 0) {
        return r.views.map(RenderRoute);
    }

    return <Route key={r.name} path={r.path} component={r.component} exact={true} />;
};

interface Props {
    routes: RouteModel[];
    location: Location;
}

export const SwitchRoutes = ({ routes, location }: Props): JSX.Element => {
    return (
        <Switch location={location}>
            {routes.map(RenderRoute)}
            <Route component={NotFound} exact={true} />
        </Switch>
    );
};

export default SwitchRoutes;
