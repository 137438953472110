import React from 'react';
import { Button } from '@vulcan/vulcan-materialui-theme';
import { ActualCostLogEntry } from 'src/ActualCostAdjustmentLog/utils/api/actual-cost-log-search';
import { makeStyles } from '@material-ui/styles';

export interface PaginationComponentProp {
    skip: number;
    take: number;
    data: ActualCostLogEntry[];
    showNext: () => void;
    showPrevious: () => void;
}

const useStyles = makeStyles({
    body: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: 10,
    },
});

export const PaginationComponent = (props: PaginationComponentProp) => {
    const styles = useStyles();
    const safeProps = Object.assign(defaultPaginationProps, props);
    const { skip, take, data, showNext, showPrevious } = safeProps;
    const { showNextButton, showPreviousButton } = getPaginationFlags(skip, take, data);
    if (data.length === 0 || (!showNextButton && !showPreviousButton)) return null;
    return (
        <div className={styles.body}>
            <Button disabled={!showPreviousButton} onClick={showPrevious} color='warning'>
                Previous
            </Button>
            <Button disabled={!showNextButton} onClick={showNext} color='warning'>
                Next
            </Button>
        </div>
    );
};

const defaultPaginationProps: PaginationComponentProp = {
    data: [],
    skip: 0,
    take: 10,
    showNext: () => {},
    showPrevious: () => {},
};

const getPaginationFlags = (skip: number, take: number, data: ActualCostLogEntry[]): { showNextButton: boolean; showPreviousButton: boolean } => {
    const isDataReturnedLessThanRequired = data.length < take;
    const canNavigateBack = skip - data.length >= 0;
    const isOnFirstPage = skip - data.length < 0;
    switch (true) {
        case isOnFirstPage && isDataReturnedLessThanRequired:
            return { showNextButton: false, showPreviousButton: false };
        case isOnFirstPage:
            return { showNextButton: true, showPreviousButton: false };
        case canNavigateBack && isDataReturnedLessThanRequired:
            return { showNextButton: false, showPreviousButton: true };
    }
    return { showNextButton: true, showPreviousButton: true };
};
