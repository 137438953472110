import * as React from 'react';
import { observer, inject } from 'mobx-react';
import UpdateProductPriceMaintenanceStore from './UpdateProductPriceMaintenanceStore';
import { RouteComponentProps } from 'react-router-dom';
import { Icon, GridContainer, GridItem } from '@vulcan/vulcan-materialui-theme';
import { IconResources } from 'src/Shared/Constants';
import { Avatar, Chip, Badge } from '@material-ui/core';

interface Props extends RouteComponentProps {
    updateProductPriceMaintenanceStore?: UpdateProductPriceMaintenanceStore;
}

@inject('updateProductPriceMaintenanceStore')
@observer
export class ErrorPanel extends React.Component<Props, {}> {
    public render(): React.ReactNode {
        const {
            errorCount,
            warningCount,
            successCount,
            showError,
            showWarning,
            showSuccess,
            toggleError,
            toggleWarning,
            toggleSuccess,
        } = this.props.updateProductPriceMaintenanceStore!;
        return (
            <GridContainer spacing={10}>
                <GridItem>
                    <Badge badgeContent={errorCount} color='secondary'>
                        <Chip
                            avatar={
                                <Avatar className='validation validation-error'>
                                    <Icon icon={IconResources.Cross} />
                                </Avatar>
                            }
                            clickable={true}
                            onClick={toggleError}
                            color='primary'
                            label={'Errors'}
                            variant={showError ? 'default' : 'outlined'}
                        />
                    </Badge>
                </GridItem>
                <GridItem>
                    <Badge badgeContent={warningCount} color='secondary'>
                        <Chip
                            avatar={
                                <Avatar className='validation validation-warning'>
                                    <Icon icon={IconResources.Warning} />
                                </Avatar>
                            }
                            clickable={true}
                            onClick={toggleWarning}
                            color='primary'
                            label={'Warnings'}
                            variant={showWarning ? 'default' : 'outlined'}
                        />
                    </Badge>
                </GridItem>
                <GridItem>
                    <Badge badgeContent={successCount} color='secondary'>
                        <Chip
                            avatar={
                                <Avatar className='validation validation-success'>
                                    <Icon icon={IconResources.Checkmark} />
                                </Avatar>
                            }
                            clickable={true}
                            onClick={toggleSuccess}
                            color='primary'
                            label={'Success'}
                            variant={showSuccess ? 'default' : 'outlined'}
                        />
                    </Badge>
                </GridItem>
            </GridContainer>
        );
    }
}
export default ErrorPanel;
