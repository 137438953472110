import * as React from 'react';
import { observer, inject } from 'mobx-react';
import { FormLabel } from '@material-ui/core';
import { GridContainer, GridItem, Button } from '@vulcan/vulcan-materialui-theme';
import { OptionModel, VulcanSelect } from '@vulcan/vulcan-materialui-theme/build/components';
import ViewProductPriceMaintenanceStore from './ViewProductPriceMaintenanceStore';

interface LocationFilterProps {
    viewProductPriceMaintenanceStore?: ViewProductPriceMaintenanceStore;
}

@inject('viewProductPriceMaintenanceStore')
@observer
export class ViewProductPriceMaintenanceFilter extends React.Component<LocationFilterProps> {
    private handleStockWarehouseChange = (selectedWarehouses: OptionModel[]): void => {
        this.props.viewProductPriceMaintenanceStore!.selectedStockWarehouseChanged(selectedWarehouses);
    };

    private handleProductCategoryChange = (selectedCategories: OptionModel[]): void => {
        this.props.viewProductPriceMaintenanceStore!.selectedProductCategoryChanged(selectedCategories);
    };

    private handleProductGroupChange = (selectedGroupes: OptionModel[]): void => {
        this.props.viewProductPriceMaintenanceStore!.selectedProductGroupChanged(selectedGroupes);
    };

    private handleStockClassificationChange = (selectedClassificationes: OptionModel[]): void => {
        this.props.viewProductPriceMaintenanceStore!.selectedStockClassificationChanged(selectedClassificationes);
    };

    private handleButtonClick = async (): Promise<void> => {
        await this.props.viewProductPriceMaintenanceStore!.updateTableControl();
    };

    public render(): JSX.Element {
        const { viewProductPriceMaintenanceStore: store } = this.props;
        return (
            <GridContainer>
                <GridItem lg={10} xs={12}>
                    <GridContainer>
                        <GridItem lg={3} xs={12}>
                            <FormLabel>Stock Warehouse</FormLabel>
                            <VulcanSelect
                                placeholder='All Warehouses'
                                options={store!.allWarehouseDataSource}
                                handleChange={this.handleStockWarehouseChange}
                                isMulti={true}
                                allowSelectAll={true}
                                closeMenuOnSelect={false}
                                isLoading={store!.loading}
                                selected={store!.selectedStockWarehouseCodes}
                            />
                        </GridItem>
                        <GridItem lg={3} xs={12}>
                            <FormLabel>Product Category</FormLabel>
                            <VulcanSelect
                                placeholder='All Product Categories'
                                options={store!.allProductCategoryDataSource}
                                handleChange={this.handleProductCategoryChange}
                                isMulti={true}
                                allowSelectAll={true}
                                closeMenuOnSelect={false}
                                isLoading={store!.loading}
                                selected={store!.selectedProductCategoryLabels}
                            />
                        </GridItem>
                        <GridItem lg={3} xs={12}>
                            <FormLabel>Product Group</FormLabel>
                            <VulcanSelect
                                placeholder='All Product Groups'
                                options={store!.allProductGroupDataSource}
                                handleChange={this.handleProductGroupChange}
                                isMulti={true}
                                allowSelectAll={true}
                                closeMenuOnSelect={false}
                                isLoading={store!.loading}
                                selected={store!.selectedProductGroupLabels}
                            />
                        </GridItem>
                        <GridItem lg={3} xs={12}>
                            <FormLabel>Class</FormLabel>
                            <VulcanSelect
                                placeholder='All Stock Classifications'
                                options={store!.allStockClassificationDataSource}
                                handleChange={this.handleStockClassificationChange}
                                isMulti={true}
                                closeMenuOnSelect={false}
                                allowSelectAll={true}
                                isLoading={store!.loading}
                                selected={store!.selectedStockClassificationLabels}
                            />
                        </GridItem>
                    </GridContainer>
                </GridItem>
                <GridItem lg={2} xs={12} align='right'>
                    <Button disabled={store!.isLoading || store!.tableDataLoading} className='filter-apply-button' type='button' color='warning' onClick={this.handleButtonClick}>
                        Apply Filter
                    </Button>
                </GridItem>
            </GridContainer>
        );
    }
}
export default ViewProductPriceMaintenanceFilter;
