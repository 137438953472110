import React from 'react';
import { makeStyles } from '@material-ui/core';
import PermMediaIcon from '@material-ui/icons/PermMedia';

const useNoEntriesStyles = makeStyles({
    root: {
        display: 'grid',
        alignItems: 'center',
        justifyItems: 'center',
        color: '#0000008a',
        padding: 10,
        height: 250,
    },
    icon: {
        fontSize: 80,
    },
});

export const NoEntriesFound = () => {
    const styles = useNoEntriesStyles();
    return (
        <div className={styles.root}>
            <PermMediaIcon className={styles.icon} />
            <h3>No results found</h3>
            <div>Try adjusting your search filter to find what you are looking for.</div>
        </div>
    );
};
