import { action, observable, runInAction } from 'mobx';
import { SettingsService } from './Middleware/SettingsService';
import BaseStore from './Infrastructure/BaseStore';
import CreateProductStore from '../CreateProduct/CreateProductStore';
import SettingStore from './Layout/SettingStore';
import { AuthStore, SecurityStore, UserProfileStore } from './Security';
import ConfigureAvailabilityStore from 'src/ConfigureAvailability/ConfigureAvailabilityStore';
import ConfigureAvailabilityTableSettingsStore from 'src/ConfigureAvailability/ConfigureAvailabilityTableSettingsStore';
import ViewProductPriceMaintenanceStore from 'src/PriceMaintenance/View/ViewProductPriceMaintenanceStore';
import UpdateProductPriceMaintenanceStore from 'src/PriceMaintenance/Update/UpdateProductPriceMaintenanceStore';
import { configureApplicationInsights } from '../Shared/AppInsights';

export class AppStore extends BaseStore {
    // All global stores will be added here, they must be private as they should only be accessible
    // by injecting them into the components where they are required.
    @observable public isSettingLoaded = false;
    public authStore: AuthStore;
    public securityStore: SecurityStore;
    private userProfileStore: UserProfileStore;
    private settingStore: SettingStore;
    private createProductStore: CreateProductStore;
    private configureAvailabilityStore: ConfigureAvailabilityStore;
    private configureAvailabilityTableSettingsStore: ConfigureAvailabilityTableSettingsStore;
    private viewProductPriceMaintenanceStore: ViewProductPriceMaintenanceStore;
    private updateProductPriceMaintenanceStore: UpdateProductPriceMaintenanceStore;

    constructor() {
        super();
        this.authStore = new AuthStore();
        this.securityStore = new SecurityStore();
        this.userProfileStore = new UserProfileStore(this.authStore);
        this.settingStore = new SettingStore();
        this.createProductStore = new CreateProductStore();
        this.configureAvailabilityTableSettingsStore = new ConfigureAvailabilityTableSettingsStore();
        this.configureAvailabilityStore = new ConfigureAvailabilityStore(this.configureAvailabilityTableSettingsStore);
        this.viewProductPriceMaintenanceStore = new ViewProductPriceMaintenanceStore();
        this.updateProductPriceMaintenanceStore = new UpdateProductPriceMaintenanceStore();
    }

    // tslint:disable-next-line:no-any
    public getStores() {
        return {
            authStore: this.authStore,
            securityStore: this.securityStore,
            userProfileStore: this.userProfileStore,
            settingStore: this.settingStore,
            createProductStore: this.createProductStore,
            configureAvailabilityStore: this.configureAvailabilityStore,
            configureAvailabilityTableSettingsStore: this.configureAvailabilityTableSettingsStore,
            viewProductPriceMaintenanceStore: this.viewProductPriceMaintenanceStore,
            updateProductPriceMaintenanceStore: this.updateProductPriceMaintenanceStore,
        };
    }

    @action public async loadAppData(): Promise<void> {
        await SettingsService.loadSettings();

        runInAction(async () => {
            this.isSettingLoaded = true;

            SettingsService.configureCrashReporting();

            this.authStore.init();

            window.authenticate = await this.authStore.login();
            configureApplicationInsights(this.authStore.getUser);

            if (this.authStore.isAuthenticated) {
                this.authStore.setLoggedInStatus(true);
                await this.securityStore.init(this.authStore);
                this.userProfileStore.init();
                this.userProfileStore.getUserProfilePhoto();
            }
        });
    }
}

export default AppStore;
