import { HttpClient } from '../http-helpers';

export interface WarehouseListItem {
    warehouseCode: string;
}

export interface WarehouseList {
    items: WarehouseListItem[];
}

export const fetchWarehouseList = async (httpClient: HttpClient, baseUrl: string) => {
    const response = await httpClient.get<WarehouseListItem[]>(`${baseUrl}/api/v10/warehouse`);
    return { items: response.parsedBody! };
};
