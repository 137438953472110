import React from 'react';
import { observer } from 'mobx-react';
import { withRouter, RouteComponentProps, generatePath, Redirect } from 'react-router-dom';
import StyledLayout from './Layout';
import AppStore from '../AppStore';
import TenantsList from '../MultiTenant/TenantsList';
import { NotAuthorised, ProgressBar } from '@vulcan/vulcan-materialui-theme';
import { AppConfig, AppConfigProvider } from 'src/ActualCostAdjustmentLog/utils/app-context/app-config-context';
import SettingsService from '../Middleware/SettingsService';
import { Notifier } from 'src/Shared/Notification/notification-context';
import { routePathConfigLookup } from '../Routes/MainRoutes';

interface MatchParams {
    prefix: string;
}

interface Props extends RouteComponentProps<MatchParams> {
    appStore: AppStore;
}

@observer
class SiteWrapper extends React.Component<Props, {}> {
    public render(): JSX.Element | null {
        if (!this.props.appStore.isSettingLoaded || !this.props.appStore.authStore.isLoggedIn) {
            return null;
        }

        const securityStore = this.props.appStore.securityStore;

        if (securityStore.userTenants.length === 0) {
            return securityStore.loadingTenants ? <ProgressBar className='circular-progress-bar-container' showProgressBar={true} type='circular' /> : <NotAuthorised />;
        }

        const paramTenantPrefix = this.props.match.params.prefix;
        const tenantName = securityStore.getTenantNameByTenantPrefix(paramTenantPrefix);
        if (tenantName) {
            securityStore.setSelectedTenant(tenantName);
        } else if (!securityStore.isTenantSelected) {
            return <TenantsList />;
        } else {
            const validParamTenantPrefix = securityStore.getTenantPrefixFromCookieTenantName;
            if (validParamTenantPrefix) {
                const path = generatePath(this.props.match.path, { prefix: validParamTenantPrefix });
                return <Redirect key='valid-tenant' to={path} exact={true} />;
            } else {
                return <TenantsList />;
            }
        }

        const routeConfig = routePathConfigLookup.get(this.props.match.path)!;
        const doNotHavePermission = securityStore!.permissionsLoaded && !securityStore!.hasPermission(routeConfig.permission);
        if (doNotHavePermission) {
            return <NotAuthorised />;
        }

        const appConfig: AppConfig = {
            apiUrls: {
                inventoryApi: SettingsService.tenantsUrls.get(window.appSettings.inventoryApiUrl)!,
            },
            displayDateFormat: window.appSettings.displayDataFormat ?? 'DD/MM/YYYY',
        };
        return (
            <AppConfigProvider contextState={appConfig}>
                <StyledLayout />
                <Notifier />
            </AppConfigProvider>
        );
    }
}

// tslint:disable-next-line:no-any
export default withRouter<any, any>(SiteWrapper);
